class CashRegister {
    id: number              = null;
    opening_comment: string = null;
    closing_comment: string = null;
    opened_at: string       = null;
    closed_at: string       = null;
    doc_link: string        = null;
    office_id: number       = null;
    label: string           = null;
    value: number           = null;

    constructor(props) {
        if (props) {
            this.id              = props.id || null;
            this.opening_comment = props.opening_comment || null;
            this.closing_comment = props.closing_comment || null;
            this.opened_at       = props.opened_at || null;
            this.closed_at       = props.closed_at || null;
            this.doc_link        = props.doc_link || null;
            this.office_id       = props.office_id || null;
            this.label           = props.label;
            this.value           = this.id;
        }
    }
}

export default CashRegister;

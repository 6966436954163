import {ReactNode} from 'react';

interface IPanel {
    title?: string,
    icon?: ReactNode,
    children: ReactNode,
    className?: string,
    width?: string
    font?: string
    titleAction?: string,
    classNameAction?: string,
    onClick?: () => void,
}

const Panel = ({icon, title, className, children, width = 'w-full', font = 'font-bold', titleAction, classNameAction, onClick}: IPanel) => {
    return (
        <div className={`${className || ''} flex flex-col ${width}`}>
            {
                title ? <div
                    className={`flex flex-row items-center h-8 bg-pdlm-gray-4 p-5 text-pdlm-blue hover:text-pdlm-blue focus:outline-none ${font}`}>
                    <div>{icon}</div>
                    <div className="ml-3">{title}</div>
                    {
                        titleAction ? <div className={classNameAction}><a onClick={onClick}>{titleAction}</a></div> : null
                    }
                </div> : null
            }
            <div className={`flex-1 px-5 pb-2 ${title ? 'pt-4' : ''} text-sm leading-normal text-pdlm-black`}>
                {children}
            </div>
        </div>
    );
};

export default Panel;

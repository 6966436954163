import {useEffect, useState} from 'react';
import {IOffice} from '../../interfaces';
import {LocationPinIcon} from '../ui/Icons/';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getOffices} from '../../services/offices.service';
import {setOffice} from '../../services/office.service';
import useOnclickOutside from 'react-cool-onclickoutside';
import ConfirmAlertModal from './ConfirmAlertModal/ConfirmAlertModal';
import {removeAllCarts} from '../../redux/slices/carts.slice';
import {removeAllFilters} from '../../redux/slices/filters.slice';
import {removeCurrentCategory} from '../../redux/slices/categories.slice';
import {useRouter} from 'next/router';
import {resetProducts} from '../../redux/slices/products.slice';
import {getOfficePaymentOptions} from '../../services/payment-options.service';

const OfficeSelect = () => {
    const dispatch                            = useAppDispatch();
    const office: IOffice                     = useAppSelector((state) => state.office);
    const offices: IOffice[]                  = useAppSelector((state) => state.offices);
    const cashRegister                        = useAppSelector((state) => state.cashRegister);
    const [showOffices, setShowOffices]       = useState(false);
    const innerBorderRef                      = useOnclickOutside(() => setShowOffices(false));
    const carts                               = useAppSelector((state) => state.carts);
    const [displayModal, setDisplayModal]     = useState(false);
    const [selectedOffice, setSelectedOffice] = useState<IOffice>(null);
    const router                              = useRouter();

    const modalContent = (
        <div className="text-center">
            Al cambiar de sucursal se borrarán los carros activos.
            <p className="text-center">¿Deseas continuar?</p>
        </div>
    );

    useEffect(() => {
        if (offices.length === 0) {
            dispatch(getOffices());
        }
    }, []);

    useEffect(() => {
        const savedOfficeIdString    = localStorage.getItem('pos.prodalam.office_id');
        const savedOfficeId          = savedOfficeIdString ? parseInt(savedOfficeIdString) : 20;
        const defaultOffice: IOffice = offices.find(o => o.id === savedOfficeId) || null;
        dispatch(setOffice(office.id !== null ? office : defaultOffice));
    }, [offices]);

    useEffect(() => {
        if (!!cashRegister.cashRegister && !!office) {
            if (cashRegister.cashRegister.office_id !== office.id) {
                dispatch(setOffice(offices.find(o => o.id === cashRegister.cashRegister.office_id)));
            }
        }
    }, [cashRegister.cashRegister, office]);

    const handleShowOfficeList = () => {
        setShowOffices(!showOffices);
    };

    const handleOfficeSelection = (office: IOffice) => {
        setShowOffices(false);
        if (!cartsAreEmpty()) {
            setSelectedOffice(office);
            handleAlertModal();
        } else {
            setSelectedOffice(null);
            changeOffice(office);
        }
    };

    const changeOffice = (office: IOffice) => {
        dispatch(setOffice(office));
        dispatch(removeAllFilters());
        dispatch(removeCurrentCategory());
        dispatch(resetProducts());
        dispatch(getOfficePaymentOptions(office.id));
        localStorage.setItem('pos.prodalam.office_id', office.id + '');
        if (router.pathname === '/') {
            router.push({
                pathname: '/',
                query: null,
            });
        }
        setShowOffices(false);
    };

    const confirmAlert = () => {
        dispatch(setOffice(selectedOffice));
        dispatch(removeAllFilters());
        dispatch(removeCurrentCategory());
        dispatch(resetProducts());
        dispatch(removeAllCarts());
        dispatch(getOfficePaymentOptions(selectedOffice.id));
        handleAlertModal();
        if (router.pathname === '/') {
            router.push({
                pathname: '/',
                query: null,
            });
        }
    };

    const cancelAlert = () => {
        handleAlertModal();
    };

    const handleAlertModal = () => {
        setDisplayModal(!displayModal);
    };

    const cartsAreEmpty = () => {
        if (carts.length >= 1) {
            return !(carts.find(cart => cart.items.length > 0) != undefined);
        } else {
            return true;
        }
    };

    return (
        <div className="relative ml-8" ref={innerBorderRef}>
            {
                <ConfirmAlertModal
                    openAlert={displayModal}
                    title="Cambio de Sucursal"
                    content={modalContent}
                    confirm={confirmAlert}
                    cancel={cancelAlert}
                    textCancelButton="Cancelar"
                    textConfirmButton="Continuar"
                />
            }
            <button
                className={`inline-flex lg:w-auto w-full px-3 rounded items-center py-1.5 bg-white whitespace-nowrap`}
                onClick={handleShowOfficeList} disabled={cashRegister.opened}>
                <div className="mr-1 flex items-center w-5">
                    <LocationPinIcon className="fill-current text-pdlm-blue"/>
                </div>
                <p className="leading-none">
                    {office?.name}
                </p>
            </button>
            {
                showOffices ?
                    <ul
                        className="absolute mt-1 w-40 bg-white z-40 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black
                        ring-opacity-5 overflow-auto focus:outline-none sm:text-sm custom-scrollbar pr-2">
                        {
                            offices?.map(of => (
                                <li className="text-gray-900 text-xs cursor-pointer py-2 px-2 hover:bg-pdlm-sky-blue-hover"
                                    onClick={() => handleOfficeSelection(of)}
                                    key={of.id} role="option">
                                    {of.id} - {of.name}
                                </li>
                            ))
                        }
                    </ul> :
                    null
            }
        </div>
    );
};

export default OfficeSelect;

import Order from '../../../models/order.model';
import NumberFormat from 'react-number-format';
import moment from 'moment/moment';
import Highlighter from '../../ui/Highlighter/Highlighter';

interface IOrderQuoteListItem {
    orderQuote?: Order
    selectedItem: any,
    selected: Order,
    documentSelected: string;
    searchText?: string
}

export const OrderQuoteListItem = (props: IOrderQuoteListItem) => {
    return (
        <div
            className={`flex flex-col border w-full px-5 py-3 space-y-1 cursor-pointer text-sm ${props.orderQuote?.sap_order === props.selected?.sap_order && 'bg-pdlm-sky-blue-4'}`}
            onClick={props.selectedItem}>
            <div className={'flex text-pdlm-blue space-x-1'}>
                <div className={'font-bold'}>
                    {`${props.documentSelected === 'VENTA' ? 'Nº de Pedido:' : 'Nº de Cotización: '}`}
                </div>
                <Highlighter
                    textToHighlight={String(props.orderQuote.sap_order)}
                    searchWords={[props.searchText]}
                />
            </div>
            <div className={'flex flex-row text-pdlm-black justify-between'}>
                <div>{moment(props.orderQuote.created_at).format('D MMM yyyy hh:mm a')}</div>
                <div>
                    <NumberFormat displayType="text" thousandSeparator="."
                                  decimalSeparator="," prefix="$"
                                  value={+props.orderQuote.total_with_discount + +props.orderQuote.delivery_price}/>
                </div>
            </div>
        </div>
    );
};

export const DotsIcon = (props) => {
    return (
        <svg width="47" height="14" viewBox="0 0 47 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M30.0302 6.99994C30.0302 10.6066 27.1061 13.5306 23.4995 13.5306C19.8928 13.5306 16.9688 10.6066 16.9688 6.99994C16.9688 3.39331 19.8928 0.469238 23.4995 0.469238C27.1061 0.469238 30.0302 3.39331 30.0302 6.99994Z"
            />
            <path
                d="M13.0611 6.99994C13.0611 10.6066 10.1373 13.5306 6.5307 13.5306C2.92372 13.5306 0 10.6066 0 6.99994C0 3.39331 2.92372 0.469238 6.5307 0.469238C10.1373 0.469238 13.0611 3.39331 13.0611 6.99994Z"
            />
            <path
                d="M46.9995 6.99994C46.9995 10.6066 44.0758 13.5306 40.4688 13.5306C36.8622 13.5306 33.9385 10.6066 33.9385 6.99994C33.9385 3.39331 36.8622 0.469238 40.4688 0.469238C44.0758 0.469238 46.9995 3.39331 46.9995 6.99994Z"
            />
        </svg>
    );
};
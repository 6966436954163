import {useState} from 'react';
import {CashWithdrawIcon, NavbarIcon, SalesSummaryIcon} from '../Icons';
import {ListAltIcon} from '../Icons';
import dynamic from 'next/dynamic';
import useOnclickOutside from 'react-cool-onclickoutside';
import {useAppSelector} from '../../../hooks/redux';
import PrinterSelection from '../../PrinterSelection/PrinterSelection';
import Metrics from '../../Metrics/Metrics';

const Orders   = dynamic(() => import('../../OrdersList/OrdersModal'));
const Withdraw = dynamic(() => import('../../CashRegister/Withdraw/CashWithdrawal'));

const MenuButton = () => {
    const [displaySection, setDisplaySection]                 = useState(false);
    const [isOpenOrdersModal, setIsOpenOrdersModal]           = useState(false);
    const [isOpenWithdrawModal, setIsOpenWithdrawModal]       = useState(false);
    const [isOpenPrinterSelection, setIsOpenPrinterSelection] = useState(false);
    const [isOpenMetrics, setIsOpenMetrics]                   = useState(false);
    const innerBorderRef                                      = useOnclickOutside(() => setDisplaySection(false));
    const {opened}                                            = useAppSelector(state => state.cashRegister);
    const handleSelectSection                                 = () => {
        setDisplaySection(!displaySection);
    };

    const openSection = (sectionName: string) => {
        switch (sectionName) {
            case 'ordersList':
                setIsOpenOrdersModal(true);
                break;
            case 'withdraw':
                setIsOpenWithdrawModal(true);
                break;
            case 'printer-selection':
                setIsOpenPrinterSelection(true);
                break;
            case 'metrics':
                setIsOpenMetrics(true);
                break;
        }
    };

    // const onClickProdesk = () => {
    //     const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    //     window.open(`${process.env.PRODESK_BASE_URL}/?token=${auth.access_token}`, '_blank');
    // };

    return (
        <>
            <button ref={innerBorderRef}
                    className="relative focus:outline-none inline-flex pointer-cursor items-center justify-center h-12 w-24"
                    onClick={handleSelectSection}>
                <NavbarIcon/>
                {
                    displaySection ?
                        <div className="absolute z-30 w-64 h-30 bg-white top-full left-0 border text-xs">
                            {/*<div className="flex py-3 px-6 hover:bg-pdlm-sky-blue-hover"*/}
                            {/*     onClick={() => openSection('cashWithdrawal')}>*/}
                            {/*    <CashWithdrawIcon/>*/}
                            {/*    <span className="ml-1 pt-1">Retiro de Efectivo</span>*/}
                            {/*</div>*/}
                            {/*<div className="flex py-3 px-6 hover:bg-pdlm-sky-blue-hover"*/}
                            {/*     onClick={() => openSection('salesCashierSummary')}>*/}
                            {/*    <StatsUpIcon width="20"/>*/}
                            {/*    <span className="ml-2">Resumen de Ventas y Caja</span>*/}
                            {/*</div>*/}
                            <div className="flex py-3 px-6 hover:bg-pdlm-sky-blue-hover"
                                 onClick={() => openSection('ordersList')}>
                                <ListAltIcon width="20"/>
                                <span className="ml-2">Ver Pedidos y Facturas</span>
                            </div>
                            {
                                opened &&
                                <div className="flex py-3 px-6 hover:bg-pdlm-sky-blue-hover items-center"
                                     onClick={() => openSection('withdraw')}>
                                    <CashWithdrawIcon width="20"/>
                                    <span className="ml-2">Retiro de Caja</span>
                                </div>
                            }
                            <div className="flex py-3 px-6 hover:bg-pdlm-sky-blue-hover"
                                 onClick={() => openSection('printer-selection')}>
                                <ListAltIcon width="20"/>
                                <span className="ml-2">Selección de Impresora</span>
                            </div>
                            <div className="flex py-3 px-6 hover:bg-pdlm-sky-blue-hover"
                                 onClick={() => openSection('metrics')}>
                                <SalesSummaryIcon/>
                                <span className="ml-2">Resumen de Ventas y Caja</span>
                            </div>
                            {/*<div className="flex py-3 px-6 hover:bg-pdlm-sky-blue-hover"*/}
                            {/*     onClick={onClickProdesk}>*/}
                            {/*    <ProdeskIcon/>*/}
                            {/*    <span className="ml-2">Prodesk</span>*/}
                            {/*</div>*/}
                        </div>
                        : null
                }
            </button>
            {
                isOpenOrdersModal && <Orders isOpen={isOpenOrdersModal} setOpen={setIsOpenOrdersModal}/>
            }
            {
                isOpenWithdrawModal && <Withdraw open={isOpenWithdrawModal} setOpen={setIsOpenWithdrawModal}/>
            }
            {
                isOpenPrinterSelection &&
                <PrinterSelection open={isOpenPrinterSelection} setOpen={setIsOpenPrinterSelection}/>
            }
            {
                isOpenMetrics && <Metrics open={isOpenMetrics} setOpen={setIsOpenMetrics}/>
            }
        </>
    );
};

export default MenuButton;

import {ReactNode} from 'react';

interface Props {
    children: ReactNode
}

const Content = ({children}: Props) => (
    <div className="flex flex-col h-full flex-shrink-1 flex-grow-0 overflow-x-auto w-full">
        {children}
    </div>
);

export default Content;

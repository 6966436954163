import {useEffect, useState} from 'react';
import {CashRegisterIcon} from '../ui/Icons';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {checkCashRegisterOpened} from '../../pages/api/cash-register';
import {initCashRegister, showModal} from '../../redux/slices/cash-register.slice';
import dynamic from 'next/dynamic';

const OpenCashRegister  = dynamic(() => import('../CashRegister/OpenCashRegister/OpenCashRegister'));
const CloseCashRegister = dynamic(() => import('../CashRegister/CloseCashRegister/CloseCashRegister'));

const CashRegister = () => {
    const [openCashRegOpenModal, setOpenCashRegOpenModal]   = useState(false);
    const [openCashRegCloseModal, setOpenCashRegCloseModal] = useState(false);
    const cashRegister                                      = useAppSelector(state => state.cashRegister);
    const dispatch                                          = useAppDispatch();

    useEffect(() => {
        checkCashRegisterOpened()
            .then(response => {
                if (response?.data?.data) {
                    dispatch(initCashRegister(response?.data?.data));
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }, []);

    useEffect(() => {
        if (cashRegister.openModal) {
            handleOpenCashRegisterOpenModal(true);
            dispatch(showModal(false));
        }
    }, [cashRegister.openModal]);

    const handleOpenCashRegisterOpenModal = (value) => setOpenCashRegOpenModal(value);

    const handleOpenCashRegisterCloseModal = (value) => setOpenCashRegCloseModal(value);

    return (
        <div>
            {
                cashRegister.opened ?
                    <CloseCashRegister display={openCashRegCloseModal} setDisplay={handleOpenCashRegisterCloseModal}/>
                    :
                    <OpenCashRegister display={openCashRegOpenModal} setDisplay={handleOpenCashRegisterOpenModal}/>
            }
            <button className="inline-flex lg:w-auto w-full px-3 rounded items-center bg-white py-1.5 ml-2 whitespace-nowrap"
                    onClick={cashRegister.opened ?
                        () => handleOpenCashRegisterCloseModal(!openCashRegCloseModal) :
                        () => handleOpenCashRegisterOpenModal(!openCashRegOpenModal)
                    }>
                <div className="mr-1 flex items-center w-5">
                    <CashRegisterIcon/>
                </div>
                <p className="leading-none">
                    {
                        cashRegister.opened ? 'Cerrar Caja' : 'Abrir Caja'
                    }
                </p>
            </button>
        </div>
    );
};

export default CashRegister;

import Select from '../ui/Select/Select';
import Button from '../ui/Button/Button';
import {DownloadIcon} from '../ui/Icons';
import {useAppSelector} from '../../hooks/redux';
import BankCheckSummaryTable from './Tables/BankCheckSummaryTable';
import CashRegisterSummaryTable from './Tables/CashRegisterSummaryTable';
import PaymentSummaryTable from './Tables/PaymentSummaryTable';
import SaleSummaryTable from './Tables/SaleSummaryTable';
import TicketAndInvoiceSummaryTable from './Tables/TicketAndInvoiceSummaryTable';
import {Fragment, useEffect, useState} from 'react';
import {cashRegistersByOffice} from '../../pages/api/cash-register';
import {LockIcon} from '../ui/Icons/LockIcon';
import {components} from 'react-select';
import {IOption} from '../../interfaces/option';
import {useDidUpdateEffect} from '../../hooks/useDidUpdateEffect';
import {cashRegisterInformation, downloadCashRegisterInformation} from '../../pages/api/metrics';
import CashRegister from '../../models/cash-register';
import {LockOn} from '../ui/Icons/LockOn';
import QuoteSummaryTable from './Tables/QuoteSummaryTable';

const CashRegisterInformation = () => {

    const [cashRegisters, setCashRegisters] = useState([]);
    const [cashRegister, setCashRegister]   = useState<CashRegister>(null);
    const officeSlice                       = useAppSelector(state => state.office);
    const officesSlice                      = useAppSelector(state => state.offices);

    const [dateFrom, setDateFrom]                               = useState('today');
    const [officeSelected, setOfficeSelected]                   = useState(officeSlice.id);
    const [cashRegisterSelected, setCashRegisterSelected]       = useState<number>(null);
    const [cashRegisterSelectedObj, setCashRegisterSelectedObj] = useState<CashRegister>(null);
    const [cashRegisterInf, setCashRegisterInf]                 = useState(null);

    const options = officesSlice.map(office => {
        return {label: office.name, value: office.id};
    });

    const dateRangeOptions: IOption[] = [
        {label: 'Hoy', value: 'today'},
        {label: 'Ayer', value: 'yesterday'},
        {label: 'Semana Anterior', value: 'last_week'},
        {label: 'Semana Actual', value: 'current_week'},
        {label: 'Mes Anterior', value: 'last_month'},
        {label: 'Mes Actual', value: 'current_month'},
    ];

    const getCashRegisters = (officeId, date) => {
        cashRegistersByOffice(officeId, date)
            .then(resp => {
                const cr = resp.data.map(d => new CashRegister(d));
                setCashRegisters(cr);
            })
            .catch(err => {
                console.error('resp', err);
            });
    };

    const getCashRegisterInformation = (officeSelected, dateFrom, cashRegisterSelected?) => {
        cashRegisterInformation(officeSelected, dateFrom, cashRegisterSelected)
            .then(response => {
                setCashRegisterInf(response.data);
            })
            .catch(reason => {
                console.error('reason:', reason);
            });
    };

    useEffect(() => {
        getCashRegisters(officeSelected, dateFrom);
    }, [officeSelected, dateFrom]);

    useDidUpdateEffect(() => {
        getCashRegisterInformation(officeSelected, dateFrom, cashRegisterSelected);
    }, [cashRegisterSelected]);

    useDidUpdateEffect(() => {
        if (cashRegisters.length > 0) {
            handleOnChangeCashRegister(cashRegisters[0]);
        } else {
            getCashRegisterInformation(officeSelected, dateFrom);
        }
    }, [cashRegisters]);

    useEffect(() => {
        const cr = cashRegisters.find(value => value.id === cashRegisterSelected);
        setCashRegisterSelectedObj(cr);
    }, [cashRegisterSelected]);

    const handleChangeDateFrom = (selected) => {
        setCashRegisters([]);
        setCashRegister(null);
        setDateFrom(selected?.value);
        getCashRegisters(officeSelected, selected?.value);
    };

    const handleOnChangeOffice = (selected) => {
        setCashRegisters([]);
        setCashRegister(null);
        setOfficeSelected(selected?.value);
        getCashRegisters(selected?.value, dateFrom);
    };

    const handleOnChangeCashRegister = (selected) => {
        setCashRegisterSelected(selected?.value);
        setCashRegister(selected);
    };

    const handleOnClickDownload = () => {
        downloadCashRegisterInformation(cashRegisterSelected)
            .then(response => {
                const url  = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href  = url;
                link.setAttribute('download', `${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
    };

    const Option = (props) => {
        return (
            <Fragment>
                <components.Option {...props} >
                    <div className={'flex flex-row w-full items-center space-x-2'}>
                        <LockIcon
                            className={`fill-current ${props.data.closed_at === null ? 'text-pdlm-green' : 'text-pdlm-red'}`}/>
                        <p>{props.children}</p>
                    </div>
                </components.Option>
            </Fragment>
        );
    };

    return (
        <div className={'w-full h-full flex flex-col px-13 py-8 space-y-6 overflow-y-auto custom-scrollbar'}>
            <div className={'flex flex-row space-x-5'}>
                <div className={'w-1/4'}>
                    <Select
                        name={'dateFrom'}
                        label={`Filtrar por Fecha`}
                        labelClassName={'text-pdlm-gray-1'}
                        singleValueColor={'#666666'}
                        defaultValue={dateFrom}
                        options={dateRangeOptions}
                        onChange={handleChangeDateFrom}
                        isClearable={false}
                    />
                </div>
                <div className={'w-1/4'}>
                    <Select
                        name={`office_id`}
                        label={`Filtrar por Sucursal`}
                        labelClassName={'text-pdlm-gray-1'}
                        singleValueColor={'#666666'}
                        defaultValue={officeSelected}
                        options={options}
                        onChange={handleOnChangeOffice}
                        isClearable={false}
                        isSearchable={true}
                    />
                </div>
                <div className={'w-1/4'}>
                    <Select
                        name={`cash_register_id`}
                        label={`Filtrar por Caja`}
                        labelClassName={'text-pdlm-gray-1'}
                        singleValueColor={'#666666'}
                        isDisabled={!cashRegister}
                        defaultValue={cashRegisterSelected}
                        options={cashRegisters}
                        onChange={handleOnChangeCashRegister}
                        components={{Option: Option}}
                        isClearable={false}
                        isSearchable={true}
                        placeholder={<div><LockIcon width={13} height={16}/></div>}
                    />
                </div>
                <div className={'flex justify-end items-end w-1/4'}>
                    <Button
                        size={'lg'}
                        outline={true}
                        onClick={handleOnClickDownload}
                        disabled={!cashRegister}
                        children={'Descargar Excel'}
                        prefixIcon={<DownloadIcon className={'mr-2'}/>}
                    />
                </div>
            </div>
            <div className={'flex flex-row space-x-5'}>
                <div className="w-1/2 space-y-5">
                    <div
                        className={'flex items-center px-5 h-10 font-bold text-pdlm-blue bg-pdlm-gray-4'}>
                        Resumen de Venta
                    </div>
                    <SaleSummaryTable billingTypeSummary={cashRegisterInf?.billing_type_summary}/>
                    <TicketAndInvoiceSummaryTable billingTypeDistribution={cashRegisterInf?.billing_type_distribution}/>
                    <QuoteSummaryTable quoteSummary={cashRegisterInf?.quote_summary}/>
                    <div
                        className={'flex items-center px-5 h-10 font-bold text-pdlm-blue bg-pdlm-gray-4'}>
                        Resumen de Pagos
                    </div>
                    <PaymentSummaryTable paymentsSummary={cashRegisterInf?.payments_summary}/>
                </div>
                <div className="w-1/2 space-y-5">
                    <div
                        className={'flex items-center justify-between px-5 h-10 font-bold text-pdlm-blue bg-pdlm-gray-4'}>
                        Resumen de Caja
                        {
                            cashRegisterSelectedObj?.closed_at == null ?
                                <LockOn className={'fill-current text-pdlm-green'}/> :
                                <LockIcon className={'fill-current text-pdlm-red'}/>
                        }
                    </div>
                    <CashRegisterSummaryTable cashSummary={cashRegisterInf?.cash_summary}
                                              closedCashRegister={cashRegisterSelectedObj?.closed_at === null}/>
                    <div
                        className={'flex items-center px-5 h-10 font-bold text-pdlm-blue bg-pdlm-gray-4'}>
                        Cheques
                    </div>
                    <BankCheckSummaryTable bankCheckSummary={cashRegisterInf?.bank_check_summary}/>

                    <div className={'flex flex-col w-full mb-20 text-sm border rounded'}>
                        {
                            cashRegister?.opening_comment &&
                            <div className={'flex flex-col w-full p-3'}>
                                <div className={'text-pdlm-black font-bold'}>Comentario Apertura</div>
                                <div>
                                    {cashRegister.opening_comment}
                                </div>
                            </div>
                        }
                        {
                            cashRegister?.closing_comment &&
                            <div className={'flex flex-col w-full border-t p-3'}>
                                <div className={'text-pdlm-black font-bold'}>Comentario Cierre:</div>
                                <div>
                                    {cashRegister.closing_comment}
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashRegisterInformation;

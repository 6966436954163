import {useEffect} from 'react';
import {useMegaMenu} from '../../contexts/MegaMenuProvider';
import {Category} from '../../interfaces';
import CategoryButton from './CategoryButton/CategoryButton';
import {getCategories} from '../../services/categories.service';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';

const SideNav = () => {
    const dispatch                              = useAppDispatch();
    const {setInitialCategory, initialCategory} = useMegaMenu();
    const categoriesSlice                       = useAppSelector(state => state.categories);
    const officeSlice                           = useAppSelector(state => state.office);

    useEffect(() => {
        if (officeSlice.id !== null && !categoriesSlice.isLoading) {
            dispatch(getCategories(officeSlice.id));
        }
    }, [officeSlice]);

    const handleOnClickCategory = (category: Category) => {
        if (category && !initialCategory || initialCategory.id != category.id) {
            setInitialCategory(category);
        } else {
            setInitialCategory(null);
        }
    };

    return (
        <div className="no-scroll-bar flex flex-col bg-gray-100 w-24 overflow-y-auto h-full flex-shrink-0">
            <CategoryButton isHeader={true}/>
            {
                categoriesSlice.categories.length === 0 ?
                    [1, 2, 3, 4, 5, 6, 7, 8].map((i) => <CategoryButton key={i} isSkeleton={true}/>)
                    :
                    categoriesSlice.categories.map((category: Category, index) => {
                        return (
                            <CategoryButton key={index}
                                            category={category}
                                            onClick={handleOnClickCategory}/>
                        );
                    })
            }
        </div>
    );
};
export default SideNav;

import {store} from 'react-notifications-component';
import {FunctionComponent, ReactNode} from 'react';

interface IToast {
    message: string | ReactNode | FunctionComponent | undefined,
    type?: 'success' | 'danger' | 'warning',
    closeIcon?: boolean,
    duration?: number
}

const Toast = ({message, type = 'success', closeIcon = true, duration = 5000}: IToast) => {
    store.addNotification(
        {
            message: message,
            type: type,
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__slideInDown', 'animate__faster'],
            animationOut: ['animate__animated', 'animate__fadeOut', 'animate__faster'],
            dismiss: {
                showIcon: closeIcon,
                duration: duration,
                pauseOnHover: true,
            },
            slidingExit: {
                duration: duration,
            },
        },
    );
};

export default Toast;

import {ReactChild, useEffect} from 'react';
import {UseFormSetValue} from 'react-hook-form';

interface ITextAreaProps {
    label: ReactChild;
    name: string;
    resizable: boolean;
    borderColor?: string;
    heightArea: string; //pixels
    readonly: boolean;
    register?: any,
    required?: boolean,
    value?: any,
    labelClassName?: string,
    placeholder?: string;
    validate?: {},
    setValue: UseFormSetValue<any>,

    onFocus?(e): void,

    autoFocus?: boolean,
}

const TextArea = ({
                      label,
                      name,
                      resizable,
                      borderColor,
                      heightArea,
                      readonly,
                      register,
                      labelClassName = '',
                      required,
                      value,
                      placeholder = '',
                      setValue,
                      validate,
                      autoFocus = false,
                      onFocus,
                  }: ITextAreaProps) => {
    let bdrColor = borderColor ? `border-${borderColor}` : 'border-pdlm-gray-1';
    let resize   = resizable ? 'resize' : 'resize-none';

    register = register ? {...register(name, {required, validate})} : register;

    useEffect(() => {
        if (value) {
            setValue(name, value);
        }
    }, []);

    return (
        <div className="flex flex-col w-full">
            <div className={`mb-1 text-pdlm-black ${labelClassName}`}>{label} {required && '*'}</div>
            <textarea
                {...register}
                readOnly={readonly}
                placeholder={placeholder}
                className={`w-full h-20 text-sm text-pdlm-black px-4 py-1 rounded placeholder-pdlm-gray-3 ${bdrColor} ${resize}`}
                style={{height: heightArea + 'px'}}
                autoFocus={autoFocus}
                onFocus={onFocus ? (e) => onFocus(e) : null}
            />
        </div>
    );
};

export default TextArea;

import {ReactNode, useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import {Controller} from 'react-hook-form';

export interface IOption {
    value: string | number | boolean,
    label: string | ReactNode,
}

export type ISelect = {
    inputRef?: any,
    options?: IOption[] | null,
    value?: any,
    errors?: any,
    name: string,
    required?: boolean,
    isClearable?: boolean,
    isDisabled?: boolean,
    isLoading?: boolean,
    isRtl?: boolean,
    withInput?: boolean,
    defaultValue?: string | number | boolean,
    label?: string,
    labelClassName?: string,
    className?: string,
    singleValueColor?: string
    placeholder?: string | ReactNode,
    isSearchable?: boolean,
    size?: 'sm' | 'md' | 'base',
    controller?: any,
    showErrorText?: boolean,
    components?: any,
    setValue?: any,
    filterOption?: any,
    onInputChange?: (value: string, action: any) => void,
    onChange?(selected: any): any
}

const Select = ({
                    inputRef,
                    options,
                    value,
                    errors,
                    isClearable = true,
                    name,
                    required,
                    isDisabled,
                    isLoading,
                    isRtl,
                    withInput,
                    isSearchable,
                    onChange,
                    defaultValue,
                    label,
                    labelClassName = '',
                    className = '',
                    singleValueColor = '#333333',
                    placeholder = 'Seleccione',
                    size = 'base',
                    controller,
                    showErrorText = true,
                    components,
                    setValue,
                    filterOption,
                    onInputChange,
                }: ISelect) => {
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const option = options.find(o => o.value === value);
        setSelectedOption(option);
    }, [value, options]);

    useEffect(() => {
        if (setValue) {
            const defaultOption = options.find(o => o.value === setValue);
            if (defaultOption) {
                setSelectedOption(defaultOption);
            } else {
                setSelectedOption(null);
            }
        }
    }, [setValue]);


    useEffect(() => {
        if (defaultValue) {
            if (isAnIOption(defaultValue)) {
                setSelectedOption(defaultValue);
                return;
            }
            const defaultOption = options.find(o => o.value === defaultValue);
            if (defaultOption) {
                setSelectedOption(defaultOption);
            } else {
                setSelectedOption(null);
            }
        }
    }, [options]);

    const isAnIOption = (obj: any): obj is IOption => {
        try {
            return 'value' in obj && 'label' in obj;
        } catch (_) {
            return false;
        }
    };
    let padding;
    switch (size) {
        case 'sm':
            padding = '4px';
            break;
        case 'base':
            padding = '6px';
            break;
        case 'md':
            padding = '8px';
            break;
    }

    const handleChange = (inputValue: any) => {
        setSelectedOption(inputValue);
        typeof onChange === 'function' && onChange(inputValue);
    };
    const customStyles = {
        container: (provided) => ({
            ...provided,
            height: size === 'sm' ? '1.75rem' : null,
            cursor: 'pointer',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: size === 'sm' ? '1.75rem' : null,
            cursor: 'pointer',
        }),
        control: (provided) => ({
            ...provided,
            height: size === 'sm' ? '1.75rem' : null,
            minHeight: size === 'sm' ? '1.75rem' : null,
            borderColor: errors ? '#DC3545' : '#005EB8',
            cursor: 'pointer',
            borderLeftWidth: withInput ? 0 : '1px',
            borderTopWidth: withInput ? 0 : '1px',
            borderBottomWidth: withInput ? 0 : '1px',
            borderRadius: withInput ? 0 : '4px',
        }),
        option: (styles, {isSelected, isFocused}) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isDisabled ? null : isSelected ? '#005EB8' : isFocused ? '#c9d6df6b' : null,
                color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? '#005EB8' : '#666666',
            };
        },
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#666666',
            padding,
            ':hover': {
                color: '#005EB8',
            },
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding,
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#CCCCCC',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: singleValueColor,
        }),
        input: (provided) => ({
            ...provided,
            'padding': '0',
        }),
    };
    return (
        <div className={className ? className : 'w-full'}>
            {
                label ?
                    <label className={`text-xs ml-1 ${labelClassName} mb-1`}
                           htmlFor={name}>{label}{required ? '*' : ''}</label>
                    : null
            }
            {
                controller ?
                    <Controller
                        control={controller}
                        defaultValue={null}
                        name={name}
                        rules={{required}}
                        render={({field: {onChange, name}}) => (
                            <>
                                <ReactSelect
                                    id={name}
                                    className={`w-full`}
                                    styles={customStyles}
                                    inputRef={inputRef}
                                    value={selectedOption}
                                    onChange={val => onChange(val.value)}
                                    placeholder={placeholder || 'Seleccione...'}
                                    options={options}
                                    isDisabled={isDisabled || false}
                                    isLoading={isLoading || false}
                                    isClearable={isClearable}
                                    isRtl={isRtl || false}
                                    isSearchable={isSearchable || true}
                                    classNamePrefix="react-select"
                                    noOptionsMessage={() => 'No hay resultados...'}
                                    components={components}
                                    filterOption={filterOption}
                                    onInputChange={onInputChange}
                                />
                            </>
                        )}
                    /> :
                    <ReactSelect
                        id={name}
                        className={`w-full`}
                        styles={customStyles}
                        inputRef={inputRef}
                        value={selectedOption}
                        onChange={handleChange}
                        placeholder={placeholder || 'Seleccione...'}
                        options={options}
                        isDisabled={isDisabled || false}
                        isLoading={isLoading || false}
                        isClearable={isClearable}
                        isRtl={isRtl || false}
                        isSearchable={isSearchable || true}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => 'No hay resultados...'}
                        components={components}
                        filterOption={filterOption}
                        onInputChange={onInputChange}
                    />
            }
            {
                (errors?.type === 'required' && showErrorText && label) &&
                <p className="text-xs mt-1 ml-1 text-pdlm-red-1">{`${label} es obligatorio`}</p>
            }
        </div>
    );
};

export default Select;

import {forwardRef, ReactNode, RefObject} from 'react';

interface iBodyModal {
    children: ReactNode,
    className?: string
}

const BodyModal = forwardRef(({children, className}: iBodyModal, ref?: RefObject<HTMLDivElement>) => {
    return (
        <div ref={ref} className={`${className || ''} w-full h-full`}>
            {children}
        </div>
    );
});

export default BodyModal;

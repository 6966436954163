import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';
import {AxiosPromise} from 'axios';

export const updateFiles = (data, files, officeId): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

    let formData = new FormData();

    formData.append('comment', data['comment']);
    formData.append('type_support', data['type_support']);
    formData.append('office_id', officeId);

    if (files) {
        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }
    }

    return AxiosInstance.post(`/support`,
        formData,
        {
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
};

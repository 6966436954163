import {AxiosPromise} from 'axios';
import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

export const createQuote = (data): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/quotes`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const sendQuoteByEmail = (id, data) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/quotes/${id}/send-email`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const convertToOrder = (sap_order) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/quotes/${sap_order}/convert-to-order`, {}, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};


export const getCurrentQuote = (params) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

    return AxiosInstance.get(`/quotes/current`, {
        params,
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    })
}
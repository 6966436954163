import Select from '../../ui/Select/Select';
import OrderQuoteList from './OrderQuoteList';
import {useContext, useEffect, useState} from 'react';
import {IOption} from '../../../interfaces/option';
import Order from '../../../models/order.model';
import Panel from '../../ui/Panel/Panel';
import SendQuoteOrderByEmail from '../../ui/SendQuoteOrderByEmail/SendQuoteOrderByEmail';

import {
    BillingDataIcon,
    BuildingIcon, CreditCardIcon,
    DeliveryIcon, DownloadIcon,
    PersonBoxIcon,
    PersonIcon,
    StoreIcon,
    TickIcon,
} from '../../ui/Icons';

import moment from 'moment/moment';
import NumberFormat from 'react-number-format';
import ProductTable from './ProductTable';
import {generateDocument} from '../../../pages/api/metrics';
import SkeletonSalesDetail from './SkeletonSalesDetail';
import {useDidUpdateEffect} from '../../../hooks/useDidUpdateEffect';
import Utils from '../../../utils/utils';
import Toast from "../../../helpers/toast";
import {AxiosError} from "axios";
import {convertToOrder} from "../../../pages/api/quotes";
import SalesDetailsFilterModal from './SalesDetailsFilterModal';
import { SlidersIcon } from '../../ui/Icons/SlidersIcon';
import { useAppSelector } from '../../../hooks/redux';
import { AuthContext } from '../../../contexts/AuthProvider';

const dateRangeOptions: IOption[] = [
    {label: 'Hoy', value: 'today'},
    {label: 'Ayer', value: 'yesterday'},
    {label: 'Semana Anterior', value: 'last_week'},
    {label: 'Semana Actual', value: 'current_week'},
    {label: 'Mes Anterior', value: 'last_month'},
    {label: 'Mes Actual', value: 'current_month'},
    {label: 'Todas las Fechas', value: 'all'},
];

const typeDocument = [
    {value: 'COTIZACION', label: 'Cotizaciones'},
    {value: 'VENTA', label: 'Pedidos'},
];

export type filtersType = {
    date: string,
    documentType: 'COTIZACION' | 'VENTA',
    seller: string,
    customer: {label: string, value: any} | null,
    office: {label: string, value: any} | null,
    sku: {label: string, value: any} | null,
}

const SalesDetails = () => {
    const authContext = useContext(AuthContext);
    const officeSlice  = useAppSelector(state => state.office);

    // Default values order or quote details
    const [orderQuoteData, setOrderQuoteData] = useState(null);
    const [generating, setGenerating]         = useState(false);

    const [loadingDetail, setLoadingDetail] = useState(false);
    const [selectedItem, setSelectedItem]   = useState<Order>(null);

    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [filters, setFilters]                 = useState<filtersType>({
        customer    : null,
        date        : 'today',
        documentType: 'VENTA',
        office      : {label: officeSlice.name, value: officeSlice.id},
        seller      : null,
        sku         : null,
    });

    useEffect(() => {
        onSellerChange(true);
    }, []);

    useDidUpdateEffect(() => {
        setOrderQuoteData(null);
    }, [filters.documentType]);

    function updateFilters(name: keyof filtersType, value: any) {
        setFilters({...filters, [name]: value});
    }

    function resetFilters () {
        setFilters((_filters: any) => {return {
            customer    : null,
            date        : 'all',
            documentType: _filters.documentType,
            office      : null,
            seller      : null,
            sku         : null,
        }});
    }

    function showDoc (withTax: boolean) {
        if (generating) return;
        let url = withTax ? orderQuoteData?.docLinkWithTax : orderQuoteData?.docLink;
        if (url) {
            window.open(url, '_blank');
        } else {
            setGenerating(true);
            generateDocument(orderQuoteData?.code, filters.documentType, withTax).then(({data}) => {
                setOrderQuoteData({
                    ...orderQuoteData,
                    docLink: data.link,
                    docLinkWithTax: data.linkWithTax,
                });
                window.open(withTax ? data.linkWithTax : data.link, '_blank');
            }).finally(() => setGenerating(false));
        }
    };

    const showConvertButton = filters.documentType === 'COTIZACION'
        && orderQuoteData?.referenceDoc == null
        && orderQuoteData?.quote_data?.class_doc === 'YPOS'
        && orderQuoteData?.quote_data?.active
        && orderQuoteData?.isLocalCreated;

    function convertQuoteToOrder() {
        convertToOrder(orderQuoteData?.code)
            .then(({data}) => {
                console.log(data);
                const referenceDoc = data.doc;
                setOrderQuoteData({...orderQuoteData, referenceDoc});
                Toast({message: data.message, type: "success"})
            })
            .catch((e: AxiosError) => {
                console.error('error', e.response.data.message);
                Toast({message: e.response.data.message, type: "danger"})
            }).finally(() => setGenerating(false));
    };

    function onSellerChange(checked: boolean) {
        if (checked) {
            const sap_code = authContext.user?.profile?.sap_code ?? `7${officeSlice?.id}`;
            if(!sap_code) {
                Toast({message: 'No tiene asignado un sap_code', type: "danger"})
                return;
            }
            updateFilters('seller', sap_code)
        } else {
            updateFilters('seller', null)
        }
    }

    return (
        <div className={'w-full h-full flex flex-col'}>
            <SalesDetailsFilterModal
                filters={filters}
                setFilters={setFilters}
                open={showMoreFilters}
                setOpen={setShowMoreFilters}
            />
            <div className={'flex flex-row gap-x-5 px-13 py-8'}>
                <Select
                    name={`type_document`}
                    value={filters.documentType}
                    label={`Filtrar por Pedido/Cotizacions`}
                    labelClassName={'text-pdlm-gray-1'}
                    singleValueColor={'#666666'}
                    isClearable={false}
                    options={typeDocument}
                    isSearchable={true}
                    onChange={(selected) => updateFilters('documentType', selected.value)}
                    className="w-52"
                />
                <Select
                    name={'date'}
                    label={`Filtrar por Fecha`}
                    labelClassName={'text-pdlm-gray-1'}
                    singleValueColor={'#666666'}
                    value={filters.date}
                    isClearable={false}
                    options={dateRangeOptions}
                    isSearchable={true}
                    onChange={(selected) => updateFilters('date', selected.value)}
                    className="w-52"
                />
                <label
                    className='flex text-xs font-normal justify-center items-center text-pdlm-gray-1 mt-6 ml-auto'>
                    <input
                        type="checkbox"
                        className='form-checkbox h-3.5 w-3.5 rounded-sm border-1 cursor-pointer focus:ring-0 focus:ring-offset-0 border-2 text-pdlm-blue-1'
                        onChange={(e) => onSellerChange(e.target.checked)}
                        checked={filters.seller!==null}
                    />
                    <span className="ml-2 whitespace-nowrap">Mis Documentos</span>
                </label>
                <button
                    className={'flex gap-2 items-center px-3 w-52 h-8 mt-6 rounded border border-pdlm-blue py-2'}
                    onClick={()=>setShowMoreFilters(true)}>
                    <SlidersIcon />
                    <div className={'text-pdlm-blue font-medium'}>Filtros</div>
                </button>
            </div>
            <div className={'flex flex-row h-full overflow-y-hidden'}>
                <div className={'flex flex-col h-full w-72 border-r border-t'}>
                    {/*List of Orders and Quotes*/}
                    <OrderQuoteList
                        isLoadingDetail={loadingDetail}
                        setIsLoadingDetail={setLoadingDetail}
                        setOrderQuote={setOrderQuoteData}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        filters={filters}
                        resetFilters={resetFilters}
                    />
                </div>
                <div className={'flex flex-col w-full h-full overflow-y-hidden px-8'}>
                    {
                        loadingDetail ?
                            <SkeletonSalesDetail/>
                            :
                            orderQuoteData === null ?
                                <div className={'flex flex-col items-center justify-center my-auto text-pdlm-gray-6'}>
                                    <div className={'text-xl mb-2'}>¡Lo Sentimos!</div>
                                    <div>Tu búsqueda no arroja resultados.</div>
                                    <div>Prueba Nuevamente</div>
                                </div> :
                                <div className={'flex flex-col h-full'}>
                                    <div
                                        className={'flex flex-col h-full w-full border-b overflow-y-auto custom-scrollbar space-y-4 px-3'}>
                                        <div className={'flex items-center justify-between'}>
                                            <div className={'flex flex-col'}>
                                                {
                                                    filters.documentType === 'VENTA' ?
                                                        <div className={'font-bold text-pdlm-blue'}>
                                                            N° de Pedido: {orderQuoteData?.code ?? ''}
                                                        </div>
                                                        :
                                                        <div className={'font-bold text-pdlm-blue'}>
                                                            N° de Cotización: {orderQuoteData?.code ?? ''}
                                                        </div>
                                                }
                                                {
                                                    filters.documentType === 'VENTA' ?
                                                        orderQuoteData?.referenceDoc &&
                                                        <div className={'font-bold text-pdlm-sky-blue-1'}>
                                                            N° de Cotización: {orderQuoteData?.referenceDoc ?? ''}
                                                        </div>
                                                        :
                                                        orderQuoteData?.referenceDoc &&
                                                        <div className={'font-bold text-pdlm-sky-blue-1'}>
                                                            N° de Pedido: {orderQuoteData?.referenceDoc ?? ''}
                                                        </div>
                                                }
                                            </div>
                                            {
                                                showConvertButton &&
                                                <button
                                                    className={'flex w-52 rounded border border-pdlm-blue justify-evenly items-center py-2'}
                                                    style={{height: '38px'}}
                                                    onClick={convertQuoteToOrder}>
                                                    <div className={'text-pdlm-blue font-medium'}>Convertir a Pedido
                                                    </div>
                                                </button>
                                            }

                                            <Select
                                                name="document"
                                                placeholder={<div className="flex items-center gap-2 text-pdlm-blue">
                                                    <DownloadIcon width={22} height={18}/>
                                                    <span>Descargar PDF</span>
                                                </div>}
                                                required
                                                isClearable={false}
                                                withInput={false}
                                                isSearchable={false}
                                                size="md"
                                                isLoading={generating}
                                                options={[
                                                    {label: <div className="flex items-center gap-2">
                                                        <DownloadIcon width={22} height={18} style={{minWidth: 22}} />
                                                        <span>Descargar PDF Valor Neto</span>
                                                    </div>, value: false
                                                    },
                                                    {label: <div className="flex items-center gap-2">
                                                        <DownloadIcon width={22} height={18} style={{minWidth: 22}} />
                                                        <span>Descargar PDF IVA Incluido</span>
                                                    </div>, value: true},
                                                ]}
                                                className="w-56 max-w-64"
                                                onChange={(val) => showDoc(val.value)}
                                            />
                                        </div>
                                        <div className={'flex w-full space-x-4'}>
                                            <Panel width={'w-1/2'}
                                                   title="Datos del Comprador"
                                                   icon={<PersonIcon/>}
                                                   font={'font-medium'}>
                                                {
                                                    <>
                                                        <p className="text-pdlm-blue text-sm font-bold ml-8">{orderQuoteData?.customer?.name}</p>
                                                        <p className="text-sm ml-8">{orderQuoteData?.customer?.rut}</p>
                                                        <p className="text-sm ml-8">{orderQuoteData?.customer?.email}</p>
                                                        <p className="text-sm ml-8">{orderQuoteData?.customer?.phone}</p>
                                                    </>
                                                }
                                            </Panel>
                                            <Panel width={'w-1/2'}
                                                   title="Datos de Entrega"
                                                   icon={<PersonBoxIcon/>}
                                                   font={'font-medium'}>
                                                {
                                                    orderQuoteData?.delivery?.deliveryDate &&
                                                    <div className="flex items-center text-sm">
                                                        <TickIcon className="mr-3"/>
                                                        <div className={`w-full text-sm text-pdlm-orange font-bold`}>
                                                            {
                                                                orderQuoteData?.delivery?.isDelivery ?
                                                                    <>Entrega el {moment(orderQuoteData?.delivery?.deliveryDate).format('DD [de] MMMM')} o antes.</> :
                                                                    <>Retiro a partir
                                                                        del {moment(orderQuoteData?.delivery?.deliveryDate).format('dddd DD [de] MMMM')}</>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className="flex mt-2 text-sm">
                                                    <div>
                                                        {
                                                            orderQuoteData?.delivery?.isDelivery ?
                                                                <DeliveryIcon className="mr-2"/> :
                                                                <StoreIcon className="mr-2"/>
                                                        }
                                                    </div>
                                                    {
                                                        orderQuoteData?.delivery?.isDelivery ?
                                                            <div className="flex flex-col">
                                                                <p className="text-sm font-bold mb-2">Despacho a
                                                                    Domicilio</p>
                                                                <p>{orderQuoteData?.delivery?.address}, {orderQuoteData?.delivery?.city}</p>
                                                                <p>{orderQuoteData?.delivery?.region}.</p>
                                                                <p>Tel: {orderQuoteData?.delivery?.phone}</p>
                                                                {
                                                                    orderQuoteData?.isGeneric ?
                                                                        <p>{orderQuoteData?.delivery?.generic}</p> :
                                                                        <>
                                                                            <p className="mt-2">
                                                                        <span
                                                                            className="font-bold">Recibe:</span> {orderQuoteData?.delivery?.name}
                                                                            </p>
                                                                            <p>
                                                                                (Rut: {orderQuoteData?.delivery?.rut} /
                                                                                Tel: {orderQuoteData?.delivery?.phone})
                                                                            </p>
                                                                        </>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="flex flex-col">
                                                                <p className="text-sm font-bold mb-2">Retiro en
                                                                    Tienda</p>
                                                                <p>{orderQuoteData?.delivery?.office?.label}</p>
                                                                <p>{orderQuoteData?.delivery?.office?.address}, {orderQuoteData?.delivery?.office?.city?.name}</p>
                                                                <p>{orderQuoteData?.delivery?.office?.city?.region?.name}.</p>
                                                                <p>
                                                                    Tel: {orderQuoteData?.delivery?.office?.contact_phones?.map((phone, key) => (
                                                                    <span key={key}>
                                                                {key > 0 ? ' / ' : null} {phone}
                                                            </span>))}
                                                                </p>
                                                                {
                                                                    orderQuoteData?.isGeneric ?
                                                                        <p>{orderQuoteData?.delivery?.generic}</p> :
                                                                        <>
                                                                            <p className="mt-2">
                                                                        <span
                                                                            className="font-bold">Retira:</span> {orderQuoteData?.delivery?.name}
                                                                            </p>
                                                                            <p>
                                                                                (Rut: {orderQuoteData?.delivery?.rut} /
                                                                                Tel: {orderQuoteData?.delivery?.phone})
                                                                            </p>
                                                                        </>
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </Panel>
                                        </div>
                                        <div className={'flex w-full space-x-4'}>
                                            <Panel width={'w-1/2'}
                                                   title="Datos de Facturación"
                                                   icon={<BillingDataIcon/>}
                                                   font={'font-medium'}>
                                                {
                                                    orderQuoteData?.billing.type &&
                                                    <div className="flex items-center text-sm">
                                                        <TickIcon className="mr-3"/>
                                                        <p className={'capitalize'}>{orderQuoteData?.billing.type}</p>
                                                    </div>
                                                }
                                                {
                                                    (orderQuoteData?.billing.type === 'Factura') &&
                                                    <div className="flex mt-2 text-sm">
                                                        <div><BuildingIcon className="mr-2"/></div>
                                                        <div className="flex flex-col text-sm">
                                                            <p className="text-sm font-bold mb-2">{orderQuoteData?.billing.data.company}</p>
                                                            <p>{orderQuoteData?.billing.data.business}</p>
                                                            <p>{orderQuoteData?.billing.data.rut}</p>
                                                            <p>{orderQuoteData?.billing.data.address}</p>
                                                            <p>{orderQuoteData?.billing.data.city}, {orderQuoteData?.billing.data.region}</p>
                                                            <p>Tel: {orderQuoteData?.billing.data.phone}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </Panel>
                                            {
                                                filters.documentType === 'COTIZACION' ?
                                                    <Panel
                                                        width={'w-1/2'}
                                                        title="Datos de la Cotización"
                                                        icon={
                                                            <CreditCardIcon
                                                                className="fill-current text-pdlm-blue w-5"
                                                            />
                                                        }
                                                        font={'font-medium'}
                                                    >
                                                        <div className="flex flex-col text-sm">
                                                            <p> N° de
                                                                Cotización: {orderQuoteData?.quote_data?.number}</p>
                                                            <p>Fecha de
                                                                Emisión: {orderQuoteData?.quote_data?.init_date}</p>
                                                            <p>Fecha de
                                                                Vencimiento: {orderQuoteData?.quote_data?.end_date}</p>
                                                            <p>Sucursal: {orderQuoteData?.quote_data?.office}</p>
                                                            <p>Vendedor: {orderQuoteData?.quote_data?.seller}</p>
                                                        </div>
                                                    </Panel>
                                                    :
                                                    <Panel
                                                        width={'w-1/2'}
                                                        title="Método de Pago"
                                                        icon={
                                                            <CreditCardIcon
                                                                className="fill-current text-pdlm-blue w-5"/>
                                                        }
                                                        font={'font-medium'}
                                                    >
                                                        <div className="flex flex-col space-y-2">
                                                            {
                                                                orderQuoteData?.payMethod?.map((p: { type: string, amount: number }, i) => {
                                                                    return (
                                                                        <div key={i} className={'flex space-x-2 items-center'}>
                                                                            <BillingDataIcon height={18} width={14}
                                                                                             className="mr-4"/>
                                                                            <div>{Utils.getPaymentMethodLabel(p.type)}</div>
                                                                            <div>
                                                                                <NumberFormat
                                                                                    prefix="$"
                                                                                    displayType="text"
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    className="w-3/12 text-right pr-4"
                                                                                    value={p?.amount || 0}/>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </Panel>
                                            }
                                        </div>
                                        <div className={'flex w-full space-x-4'}>
                                            <Panel width={'w-1/2'}
                                                   title={`Resumen de ${filters.documentType === 'VENTA' ? 'Pedido' : 'Cotización'}`}
                                                   icon={<PersonIcon/>}
                                                   font={'font-medium'}>
                                                <div className="flex flex-col text-sm ml-8">
                                                    <div className="flex justify-between">
                                                        <p>Subtotal:</p>
                                                        <p><NumberFormat displayType="text" thousandSeparator="."
                                                                         decimalSeparator="," prefix="$"
                                                                         value={orderQuoteData?.paymentSummary.subtotal}/>
                                                        </p>
                                                    </div>
                                                    {orderQuoteData?.delivery?.isDelivery ?
                                                        <div className="flex justify-between">
                                                            <p>Despacho:</p>
                                                            <p><NumberFormat displayType="text" thousandSeparator="."
                                                                             decimalSeparator="," prefix="$"
                                                                             value={orderQuoteData?.paymentSummary.deliveryPrice}/>
                                                            </p>
                                                        </div> :
                                                        <div className="flex justify-between">
                                                            <p>Retiro en Tienda:</p>
                                                            <p>Gratis</p>
                                                        </div>
                                                    }
                                                    <div className="flex justify-between">
                                                        <p>Descuentos:</p>
                                                        <p><NumberFormat displayType="text" thousandSeparator="."
                                                                         decimalSeparator="," prefix="$"
                                                                         value={orderQuoteData?.paymentSummary.discount}/>
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <p className="text-pdlm-blue">Total:</p>
                                                        <p><NumberFormat displayType="text"
                                                                         thousandSeparator="."
                                                                         decimalSeparator=","
                                                                         prefix="$"
                                                                         className={'text-pdlm-blue'}
                                                                         value={orderQuoteData?.paymentSummary.total}/>
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <p>Total sin IVA:</p>
                                                        <p><NumberFormat displayType="text" thousandSeparator="."
                                                                         decimalSeparator="," prefix="$"
                                                                         value={orderQuoteData?.paymentSummary.totalWithoutTax}/>
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <p>IVA 19%:</p>
                                                        <p><NumberFormat displayType="text" thousandSeparator="."
                                                                         decimalSeparator="," prefix="$"
                                                                         value={orderQuoteData?.paymentSummary.tax}/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </div>
                                        <ProductTable orderQuoteData={orderQuoteData}/>
                                    </div>
                                    <SendQuoteOrderByEmail isOrder={filters.documentType !== 'COTIZACION'}
                                                           orderId={selectedItem?.sap_order}
                                                           sendOrderLabel={'Enviar Pedido'}
                                                           sendQuoteLabel={'Enviar Cotización'}
                                                           isFromMetrics={true}
                                    />
                                </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SalesDetails;

const SkeletonSalesDetail = () => {
    return (
        <div
            className={'flex flex-col h-full w-full animate-pulse space-y-5 overflow-y-auto custom-scrollbar'}>
            <div className={'flex flex-row animate-pulse space-x-2 w-full'}>
                <div className={'flex flex-col w-1/2 space-y-3'}>
                    <div className={'h-8 w-full bg-pdlm-gray-3'}/>
                    <div className={'w-full px-3 space-y-1'}>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                    </div>
                </div>
                <div className={'flex flex-col w-1/2 space-y-3'}>
                    <div className={'h-8 w-full bg-pdlm-gray-3'}/>
                    <div className={'w-full px-3 space-y-1'}>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                    </div>
                </div>
            </div>
            <div className={'flex flex-row animate-pulse space-x-2 w-full'}>
                <div className={'flex flex-col w-1/2 space-y-3'}>
                    <div className={'h-8 w-full bg-pdlm-gray-3'}/>
                    <div className={'w-full px-3 space-y-1'}>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                    </div>
                </div>
                <div className={'flex flex-col w-1/2 space-y-3'}>
                    <div className={'h-8 w-full bg-pdlm-gray-3'}/>
                    <div className={'w-full px-3 space-y-1'}>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                    </div>
                </div>
            </div>
            <div className={'flex flex-row animate-pulse space-x-2 w-full'}>
                <div className={'flex flex-col w-1/2 space-y-3'}>
                    <div className={'h-8 w-full bg-pdlm-gray-3'}/>
                    <div className={'w-full px-3 space-y-1'}>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                    </div>
                </div>
                <div className={'flex flex-col w-1/2 space-y-3'}>
                    <div className={'h-8 w-full bg-pdlm-gray-3'}/>
                    <div className={'w-full px-3 space-y-1'}>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                        <div className={'h-6 w-full bg-pdlm-gray-3'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonSalesDetail;
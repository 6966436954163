import { useEffect, useState } from 'react'

/**
 *
 * @param value: value to be debounced
 * @param delay: delay in milliseconds
 *
 * example:
 * const [search, setSearch] = useState('');
 * const searchDebounce      = useDebounce(search, 300);
 * // searchDebounce will be updated 300ms after search is updated
 */

export function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500)
        return () => clearTimeout(timer)
    }, [value, delay])

    return debouncedValue
}
import {OrderQuoteListItem} from './OrderQuoteListItem';
import Order from '../../../models/order.model';
import {getSaleDetail} from '../../../pages/api/metrics';
import React, {useEffect, useRef, useState} from 'react';
import {useDidUpdateEffect} from '../../../hooks/useDidUpdateEffect';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import { filtersType } from './SalesDetails';
import { useDebounce } from '../../../hooks/useDebounce';
import useSWR from 'swr';
import { fetcher } from '../../../pages/api/products';
import Utils from '../../../utils/utils';

interface IOrderQuoteList {
    setOrderQuote: any;
    isLoadingDetail: boolean;
    setIsLoadingDetail: any;
    setSelectedItem: any;
    selectedItem: Order;
    filters: filtersType,
    resetFilters: () => void,
}

const OrderQuoteList = ({
                            setOrderQuote,
                            selectedItem,
                            setSelectedItem,
                            isLoadingDetail,
                            setIsLoadingDetail,
                            filters,
                            resetFilters,
                        }: IOrderQuoteList) => {
    const [searchText, setSearchText] = useState('');
    const searchTextDebounce = useDebounce(searchText, 300);
    const [offset, setOffset] = useState(0);
    const listInnerRef = useRef<HTMLDivElement>(null);

    let params: any = {
        office_id: filters.office?.value || -1,
        date: filters.date,
        type: filters.documentType,
        q: searchTextDebounce,
        offset: offset,
        sku: filters.sku?.value,
        seller: filters.seller,
        customer: filters.customer?.value,
    };
    if(searchTextDebounce) {
        params = {
            date: 'all',
            office_id: -1,
            type: filters.documentType,
            q: searchTextDebounce,
            offset: offset,
        };
    }
    const {data, isLoading} = useSWR('metrics/sale-details?' + Utils.getStrParams(params), fetcher());
    const [orderQuoteList, setOrderQuoteList] = useState<Order[]>([]);

    useEffect(() => {
        if(isLoading) return;
        if (offset === 0) {
            if (listInnerRef.current) {
                listInnerRef.current?.scrollTo(0, 0);
            }
            // set new items
            setOrderQuoteList(data?.data?.map((d: any) => new Order(d)) ?? []);
        } else {
            // append new items
            setOrderQuoteList((ordersQuotes) => [...ordersQuotes, ...data?.data?.map((d: any) => new Order(d)) ?? []]);
        }
    }, [data, offset, isLoading]);

    // reset pagination and filters when search text changes
    useEffect(() => {
        resetFilters();
        setOffset(0);
    }, [searchTextDebounce]);

    // reset pagination when filters change
    useEffect(() => {
        setOffset(0);
    }, [filters]);

    // set first item as selected
    useEffect(() => {
        if(selectedItem) return;
        setSelectedItem(orderQuoteList?.[0]);
        if((orderQuoteList?.length??0) === 0) {
            setSelectedItem(null);
            setOrderQuote(null);
        }
    }, [orderQuoteList, selectedItem]);

    useDidUpdateEffect(() => {
        setIsLoadingDetail(true);
        if (selectedItem) {
            getSaleDetail(selectedItem?.sap_order)
            .then(resp => {
                setOrderQuote(resp.data?.data);
            })
            .catch(() => {
                setOrderQuote(null);
            })
            .finally(() => {
                setIsLoadingDetail(false);
            });
        } else {
            setOrderQuote(null);
            setIsLoadingDetail(false);
        }
    }, [selectedItem]);

    const getMoreOrders = () => {
        setOffset(orderQuoteList.length);
    };

    const handleClear = () => {
        setSearchText('');
    };

    const onScroll = () => {
        if (listInnerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                getMoreOrders();
            }
        }
    };

    return (
        <>
            <div className={'flex flex-col items-left py-5 px-8 space-y-4'}>
                <div className={'text-pdlm-blue font-bold'}>
                    {filters.documentType === 'VENTA' ? 'Listado de Pedidos' : 'Listado de Cotizaciones'}
                </div>
                <div className={'flex flex-col space-y-2'}>
                    <div className={'text-xs text-pdlm-gray-2'}>
                        {filters.documentType === 'VENTA' ? 'Nº de Pedido' : 'Nº de Cotización'}
                    </div>
                    <div className="flex border border-pdlm-gray-3 bg-white rounded">
                        <input
                            id="search-input"
                            className={`w-full text-gray-700 leading-tight border-0 placeholder-pdlm-gray-3 focus:ring-0 text-sm rounded`}
                            type="text"
                            name="search"
                            autoComplete="off"
                            placeholder={'000000000'}
                            onChange={(v) => setSearchText(v.target.value)}
                            value={searchText}
                        />
                        {
                            <div className="w-4 flex justify-center items-center">
                                {
                                    searchText?.length > 0 &&
                                    <a className={`cursor-pointer px-1 bg-white text-pdlm-gray-5 font-medium`}
                                       onClick={handleClear}>x</a>
                                }
                            </div>
                        }
                        <div className={`flex items-center h-full m-auto border-0 px-4 rounded bg-white cursor-pointer`} >
                            <FontAwesomeIcon className={'text-pdlm-blue'} icon={faSearch}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'h-full w-72 overflow-y-auto custom-scrollbar'} onScroll={() => onScroll()}
                 ref={listInnerRef}>
                {
                    isLoading && offset===0 ?
                        <div className={'flex h-full w-full items-center justify-center px-4'}>
                            Buscando...
                        </div>
                        :
                        orderQuoteList.length > 0 ?
                            orderQuoteList.map(
                                (orderQuote, i) => <OrderQuoteListItem key={orderQuote.sap_order+'_'+i}
                                                                  orderQuote={orderQuote}
                                                                  selectedItem={() => !isLoadingDetail && setSelectedItem(orderQuote)}
                                                                  selected={selectedItem}
                                                                  documentSelected={filters.documentType}
                                                                  searchText={searchText}
                                />,
                            ) :
                            <div className={'flex h-full w-full items-center justify-center px-4'}>
                                Sin Resultados
                            </div>
                }
            </div>
        </>
    );
};

export default OrderQuoteList;

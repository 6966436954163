import Modal from '../ui/Modal/Modal';
import React, {useEffect, useRef, useState} from 'react';
import HeaderModal from '../ui/Modal/HeaderModal';
import BodyModal from '../ui/Modal/BodyModal';
import {PriceIcon} from '../ui/Support/PriceIcon';
import ButtonIcon from './ButtonIcon';
import {CatalogIcon} from '../ui/Support/CatalogIcon';
import {SystemPosIcon} from '../ui/Support/SystemPosIcon';
import {DotsIcon} from '../ui/Support/DotsIcon';
import {FormProvider, useForm} from 'react-hook-form';
import Button from '../ui/Button/Button';
import TextArea from '../ui/Form/TextArea/TextArea';
import {FileInputIcon} from '../ui/Support/FileInputIcon';
import {InfoIcon} from '../ui/Support/InfoIcon';
import {updateFiles} from '../../pages/api/supports';
import Toast from '../../helpers/toast';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {setLoading} from '../../redux/slices/loading.slice';

interface ISupport {
    open: boolean,
    setOpen: any,
    displayBackButton: boolean
}


const Support = ({open, setOpen, displayBackButton = false}: ISupport) => {
    const ref                 = useRef();
    const bodyRef             = useRef();
    const [height, setHeight] = useState(0);
    const [errors, setErrors] = useState([]);
    const methods             = useForm({mode: 'onChange'});
    const field               = methods.watch('type_support');
    const office              = useAppSelector(state => state.office);
    const dispatch            = useAppDispatch();

    const [uploadFiles, setUploadFiles] = useState<File[]>([]);

    useEffect(() => {
        // @ts-ignore
        setHeight(bodyRef?.current?.clientHeight);
    }, [bodyRef?.current]);

    const handleData = (data) => {
        dispatch(setLoading(true));
        updateFiles(data, uploadFiles.length > 0 ? uploadFiles : null, office.id)
            .then(() => {
                Toast({message: '¡Gracias por ayudarnos a mejorar!', type: 'success'});
                setOpen(false);
            })
            .catch((error) => {
                if (error?.response?.data?.errors) {
                    const propertyNames = Object.keys(error?.response?.data?.errors);
                    const indexes       = propertyNames.map(propertyName => Number(propertyName.split('.')[1]));
                    const failedFiles   = indexes.map(index => uploadFiles[index]);
                    setErrors(failedFiles);
                }
                    Toast({message: 'Tamaño excede máximo o formato no permitido. Formato permitido: jpg, jpeg, png, y gif hasta 5 MB.', type: 'danger'});
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    const handleUpload = (e) => {
        const file = e.target.files;
        setUploadFiles([...uploadFiles, ...file]);
    };

    const handleRemoveFile = (index) => {
        const _uploadFiles = [...uploadFiles];

        if (index > -1) {
            _uploadFiles.splice(index, 1);
        }

        setUploadFiles([..._uploadFiles]);
    };

    return (
        <Modal openModal={open}
               setOpenModalState={setOpen}
               refModal={ref}
               widthSize={'medium'}
               customHeightSize={'h-5/6'}
            // maxWidthSize={'max-w-discount'}
        >
            <HeaderModal setOpenModalState={setOpen} className="text-pdlm-blue" displayCloseIcon={false}>
                <div className={'flex items-center justify-between space-x-5'}>
                    {
                        displayBackButton ?
                            <div className={'flex w-1/3 items-center space-x-2 cursor-pointer'}
                                 onClick={() => setOpen(false)}>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.5 7.5H4.33L9.92 1.91L8.5 0.5L0.5 8.5L8.5 16.5L9.91 15.09L4.33 9.5H16.5V7.5Z"
                                        fill="#49A4E5"/>
                                </svg>
                                <div className={'text-sm text-pdlm-sky-blue-1'}>Volver al Detalle del Producto</div>
                            </div>
                            :
                            <div className={'w-1/3'}/>
                    }
                    <div className={'w-1/3'}>
                        ¿Necesitas ayuda o tienes alguna sugerencia?
                    </div>
                    <div className={'flex w-1/3 justify-end'}>
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={'cursor-pointer'}
                            onClick={() => setOpen(false)}>
                            <path
                                d="M14.5 1.91L13.09 0.5L7.5 6.09L1.91 0.5L0.5 1.91L6.09 7.5L0.5 13.09L1.91 14.5L7.5 8.91L13.09 14.5L14.5 13.09L8.91 7.5L14.5 1.91Z"
                                fill="black"
                                fillOpacity="0.54"
                            />
                        </svg>
                    </div>
                </div>
            </HeaderModal>
            <BodyModal ref={bodyRef} className={'border-t'}>
                <FormProvider {...methods} >
                    <div className={'flex flex-col h-full w-full justify-center items-center overflow-y-hidden'}
                         style={{height: height + 'px'}}>
                        <div className={'h-full w-full overflow-y-auto custom-scrollbar'}>
                            <div className={'flex w-full justify-center py-8 text-pdlm-black'}>
                                {
                                    field == null ?
                                        'Selecciona una de las siguientes opciones para poder continuar.'
                                        : 'Completa la información que se desglosa a continuación.'
                                }
                            </div>
                            <div className={'flex w-full mb-14 justify-center space-x-10'}>
                                <ButtonIcon
                                    children={
                                        <PriceIcon
                                            className={` fill-current ${field == null ? 'text-pdlm-sky-blue-1' : (field == 'PRICE' && field != null ? 'text-white' : 'text-pdlm-gray-7')}`}
                                        />}
                                    text={'Precio'}
                                    value={'PRICE'}
                                />
                                <ButtonIcon
                                    children={
                                        <CatalogIcon
                                            className={` fill-current ${field == null ? 'text-pdlm-sky-blue-1' : (field == 'CATALOG' && field != null ? 'text-white' : 'text-pdlm-gray-7')}`}
                                        />}
                                    text={'Catalogo'}
                                    value={'CATALOG'}
                                />
                                <ButtonIcon
                                    children={
                                        <SystemPosIcon
                                            className={` fill-current ${field == null ? 'text-pdlm-sky-blue-1' : (field == 'POS' && field != null ? 'text-white' : 'text-pdlm-gray-7')}`}
                                        />}
                                    text={'Sistema POS'}
                                    value={'POS'}
                                />
                                <ButtonIcon
                                    children={
                                        <DotsIcon
                                            className={`fill-current ${field == null ? 'text-pdlm-sky-blue-1' : (field == 'OTHERS' && field != null ? 'text-white' : 'text-pdlm-gray-7')}`}
                                        />}
                                    text={'Otro'}
                                    value={'OTHERS'}
                                />
                            </div>

                            {/*Comment section*/}
                            <div className={'flex w-full justify-center'}>
                                {
                                    field !== null &&
                                    <div className={'w-96'}>
                                        <TextArea label={'Comentario'}
                                                  labelClassName={'text-sm'}
                                                  required={true}
                                                  name={'comment'}
                                                  placeholder={'Describe detalladamente el problema que has detectado.'}
                                                  resizable={false}
                                                  heightArea={'100px'}
                                                  readonly={false}
                                                  setValue={methods.setValue}
                                                  register={methods.register}
                                        />
                                    </div>
                                }
                            </div>

                            {/*File input section*/}
                            {
                                field !== null &&
                                <div className={'flex flex-col w-full justify-center items-center my-8'}>
                                    <div className={'flex flex-col w-96 justify-center items-center space-y-4'}>
                                        {/*Advice*/}
                                        <div className={'flex w-full bg-pdlm-gray-4 text-sm px-4 py-2'}>
                                            Para ayudarnos a tener la información más completa,
                                            adjunta los archivos que tengas para este caso.
                                        </div>
                                        {/*Input*/}
                                        <div className={'flex w-full justify-between items-center'}>
                                            <div className={'flex space-x-4'}>
                                                <FileInputIcon/>
                                                <label htmlFor="file" className={'underline text-pdlm-sky-blue-1'}>
                                                    Adjunta los archivos aquí
                                                </label>
                                                <input
                                                    id={'file'}
                                                    className={'hidden'}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleUpload}
                                                    multiple
                                                />
                                            </div>
                                            <InfoIcon/>
                                        </div>

                                        {/*Files*/}
                                        <div className={'flex flex-wrap w-full'}>
                                            {
                                                uploadFiles.map((file, i) => (
                                                    <div
                                                        key={i}
                                                        className={`flex items-center text-sm rounded-full px-4 py-2 ${errors.filter(err => err?.name === file?.name).length > 0 ? 'bg-pdlm-red-7' : 'bg-pdlm-gray-9'} space-x-2 h-6 m-2`}>
                                                        <p className={'text-pdlm-black'}>{file?.name}</p>
                                                        <button className="focus:outline-none"
                                                                onClick={() => handleRemoveFile(i)}>
                                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                 className={'cursor-pointer'}>
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                      d="M0.363213 0.413598C0.714685 0.0621259 1.28453 0.0621259 1.63601 0.413598L5.49961 4.2772L9.36321 0.413598C9.71468 0.0621259 10.2845 0.0621259 10.636 0.413598C10.9875 0.76507 10.9875 1.33492 10.636 1.68639L6.7724 5.54999L10.636 9.4136C10.9875 9.76507 10.9875 10.3349 10.636 10.6864C10.2845 11.0379 9.71468 11.0379 9.36321 10.6864L5.49961 6.82279L1.63601 10.6864C1.28453 11.0379 0.714685 11.0379 0.363213 10.6864C0.0117414 10.3349 0.0117414 9.76507 0.363213 9.4136L4.22682 5.54999L0.363213 1.68639C0.0117414 1.33492 0.0117414 0.76507 0.363213 0.413598Z"
                                                                      fill="#49A4E5"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            errors.length > 0 &&
                                            <div className={'w-full mb-10'}>
                                                <div className={'flex h-24'} style={{
                                                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                                                }}>
                                                    <div className={'w-4 bg-pdlm-red'}/>
                                                    <div className={'flex flex-col w-full justify-evenly text-sm px-4'}>
                                                        {errors.map((err, idx) => <span key={idx}
                                                                                        className={'font-bold'}>{err?.name}</span>)}
                                                        <span
                                                            className={''}>Tamaño excede máximo o formato no permitido.</span>
                                                        <span className={'text-xs italic'}>Formato permitido: jpg, jpeg, png, y gif hasta 5 MB.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={'flex items-center justify-center w-full py-3 border-t px-13 space-x-4'}>
                            <Button children={'Cancelar'}
                                    size={'lg'}
                                    outline={true}
                                    onClick={() => setOpen(false)}/>
                            <Button children={'Enviar'}
                                    size={'lg'}
                                    disabled={!methods.formState.isValid}
                                    onClick={methods.handleSubmit(handleData)}/>
                        </div>
                    </div>
                </FormProvider>
            </BodyModal>
        </Modal>
    );
};

export default Support;
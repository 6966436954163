import {AxiosPromise} from 'axios';
import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

export const createCashRegister = (data): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/cash-registers/open`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const checkCashRegisterOpened = (): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`/cash-registers/check-opened`, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const closeCashRegister = (data): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/cash-registers/close`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const createWithdrawal = (data): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/cash-registers/withdrawal`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const getDailySummary = (data): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`/cash-registers/daily-summary`, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
        params: data,
    });
};

export const sendCashRegisterDocByEmail = (id, data): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/cash-registers/${id}/send-email`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const cashRegistersByOffice = (office_id, date, cashRegisterId = null) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`cash-registers/by-office`, {
        params: {
            office_id,
            date,
            cashRegisterId,
        },
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const getBankChecks = (cashRegisterId) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`cash-registers/bank-checks`, {
        params: {
            cash_register_id: cashRegisterId,
        },
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

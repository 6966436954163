import Select from '../ui/Select/Select';
import {useAppSelector} from '../../hooks/redux';
import Button from '../ui/Button/Button';
import {DownloadIcon} from '../ui/Icons';
import PaymentSummaryTable from './Tables/PaymentSummaryTable';
import TicketAndInvoiceSummaryTable from './Tables/TicketAndInvoiceSummaryTable';
import {useEffect, useState} from 'react';
import {downloadSalesSummary, getSalesSummary} from '../../pages/api/metrics';
import {IOption} from '../../interfaces/option';
import BillingTypeSummaryTable from './Tables/BillingTypeSummaryTable';
//import DeliveryTable from './Tables/DeliveryTable';
import QuoteSummaryTable from './Tables/QuoteSummaryTable';

const SalesSummary = () => {
    const officesSlice = useAppSelector(state => state.offices);
    const officeSlice  = useAppSelector(state => state.office);

    const [dateSelected, setDateSelected]     = useState('today');
    const [officeSelected, setOfficeSelected] = useState(officeSlice.id);

    const [paymentsSummary, setPaymentsSummary]                 = useState(null);
    const [billingTypeSummary, setBillingTypeSummary]           = useState(null);
    const [quoteSummary, setQuoteSummary]                       = useState(null);
    const [billingTypeDistribution, setBillingTypeDistribution] = useState(null);
    //const [deliveryTypeSummary, setDeliveryTypeSummary]         = useState(null);

    const options = officesSlice.map(office => {
        return {label: office.name, value: office.id};
    });

    const dates: IOption[] = [
        {label: 'Hoy', value: 'today'},
        {label: 'Ayer', value: 'yesterday'},
        {label: 'Semana Anterior', value: 'last_week'},
        {label: 'Semana Actual', value: 'current_week'},
        {label: 'Mes Anterior', value: 'last_month'},
        {label: 'Mes Actual', value: 'current_month'},
    ];

    const handleOnChangeDate = (selected) => {
        setDateSelected(selected.value);
    };

    const handleOnChangeOffice = (selected) => {
        setOfficeSelected(selected?.value);
    };

    useEffect(() => {
        getSalesSummary(officeSelected, dateSelected)
            .then(response => {
                setPaymentsSummary(response?.data?.payments_summary);
                setBillingTypeSummary(response?.data?.billing_type_summary);
                setBillingTypeDistribution(response?.data?.billing_type_distribution);
                //setDeliveryTypeSummary(response?.data?.delivery_type_summary);
                setQuoteSummary(response?.data?.quote_summary);
            })
            .catch(reason => {
                console.error('reason', reason.error);
            })
            .finally(() => {
            });
    }, [dateSelected, officeSelected]);

    const handleOnClickDownload = () => {
        downloadSalesSummary(officeSelected, dateSelected)
            .then(response => {
                const url  = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href  = url;
                link.setAttribute('download', `${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
    };

    return (
        <div className={'w-full h-full flex flex-col px-13 py-8 space-y-6 overflow-y-auto custom-scrollbar'}>
            <div className={'flex flex-row space-x-5'}>
                <div className={'w-1/4'}>
                    <Select
                        name={'date'}
                        label={`Filtrar por Fecha`}
                        labelClassName={'text-pdlm-gray-1'}
                        singleValueColor={'#666666'}
                        defaultValue={dateSelected}
                        isClearable={false}
                        options={dates}
                        isSearchable={true}
                        onChange={handleOnChangeDate}
                    />
                </div>
                <div className={'w-1/4'}>
                    <Select
                        name={`office_id`}
                        label={`Filtrar por Sucursal`}
                        labelClassName={'text-pdlm-gray-1'}
                        singleValueColor={'#666666'}
                        defaultValue={officeSelected}
                        isClearable={false}
                        options={options}
                        isSearchable={true}
                        onChange={handleOnChangeOffice}
                    />
                </div>
                <div className={'w-1/4'}/>
                <div className={'flex justify-end items-end w-1/4'}>
                    <Button
                        size={'lg'}
                        outline={true}
                        children={'Descargar Excel'}
                        prefixIcon={<DownloadIcon className={'mr-2'}/>}
                        onClick={handleOnClickDownload}
                    />
                </div>
            </div>
            <div className={'flex flex-row space-x-5'}>
                <div className="w-1/2 space-y-6">
                    <div className={'flex items-center px-5 h-10 font-bold text-pdlm-blue bg-pdlm-gray-4'}>
                        Resumen de Pagos
                    </div>
                    <PaymentSummaryTable paymentsSummary={paymentsSummary}/>
                </div>
                <div className="w-1/2 space-y-6">
                    <div className={'flex items-center px-5 h-10 font-bold text-pdlm-blue bg-pdlm-gray-4'}>
                        Resumen de Venta
                    </div>
                    <BillingTypeSummaryTable billingTypeSummary={billingTypeSummary}/>
                    <TicketAndInvoiceSummaryTable billingTypeDistribution={billingTypeDistribution}/>
                    <QuoteSummaryTable quoteSummary={quoteSummary}/>
                    {/*<div className={'flex items-center px-5 h-10 font-bold text-pdlm-blue bg-pdlm-gray-4'}>*/}
                    {/*    Despachos y Retiros*/}
                    {/*</div>*/}
                    {/*<DeliveryTable deliveryTypeSummary={deliveryTypeSummary}/>*/}
                </div>
            </div>
        </div>
    );
};

export default SalesSummary;

export default class Order {
    id: number                  = null;
    code: string                = null;
    sap_order: number           = null;
    total_amount: number        = 0;
    products_quantity: number   = 0;
    type: string                = null;
    sale_status: string         = null;
    billing_type: string        = null;
    office_id: number           = 0;
    customer_id: number         = 0;
    created_at: string          = null;
    updated_at: string          = null;
    payment_type: null          = null;
    sap_client: string          = null;
    notify: string              = null;
    notified_at: string         = null;
    attempts: number            = 0;
    sap_doc_link: null          = null;
    sap_pickup_number: null;
    delivery_price: number      = 0;
    tracking_code: string;
    source: string;
    expire_at: Date;
    discount: string;
    total_with_discount: number = 0;
    user_id: number;
    cash_register_id: null;

    constructor(props) {
        if (props) {
            this.id                  = props.id || null;
            this.sap_order           = props.sap_order || null;
            this.created_at          = props.created_at || null;
            this.total_amount        = props.total_amount || null;
            this.total_with_discount = props.total_with_discount || null;
            this.delivery_price      = props.delivery_price || null;
        }
    }
}
import React, {ReactChild, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';

interface IButtonIcon {
    children?: ReactChild,
    text: string,
    value: string
}

const ButtonIcon = ({text, children, value}: IButtonIcon) => {
    const {setValue, watch, register} = useFormContext();
    const field                       = watch('type_support');
    const [isSelected, setIsSelected] = useState(false);

    const onClick = () => {
        setValue('type_support', value);
    };

    useEffect(() => {
        if (String(field) === value) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [field]);

    return (
        <div
            className={`flex flex-col items-center justify-center space-y-2 w-30 h-26 rounded shadow-xl cursor-pointer ${isSelected ? 'bg-pdlm-blue-1' : 'border border-solid border-pdlm-blue'}`}
            onClick={onClick}>
            <input id={value}
                   type="radio"
                   className={'hidden'}
                   name={'type_support'}
                   value={value} {...register('type_support', {required: true})}/>
            {children}
            <label
                className={`${field == null || false ? 'text-pdlm-sky-blue-1' : (isSelected ? 'text-white' : 'text-pdlm-gray-7')} font-bold`}>
                {text}
            </label>
        </div>
    );
};

export default ButtonIcon;
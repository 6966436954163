import React, { useRef } from 'react';
import Modal from '../../ui/Modal/Modal';
import BodyModal from '../../ui/Modal/BodyModal';
import Button from '../../ui/Button/Button';
import AsyncSelect from '../../ui/Select/AsyncSelect';
import Select from '../../ui/Select/Select';
import { useAppSelector } from '../../../hooks/redux';

type SalesDetailsFilterModalProps = {
    filters: {[key: string]: any},
    setFilters: (filters: any) => void,
    open?: boolean,
    setOpen?: (open: boolean) => void,
    disabled?: boolean,
}

export default function SalesDetailsFilterModal({filters, setFilters, open, setOpen, disabled}: SalesDetailsFilterModalProps) {
    const filtersRef = useRef<{ [key: string]: any }>(filters || {});
    const officesSlice = useAppSelector(state => state.offices);

    const officeOptions = [{label: 'Todas las sucursales', value: -1}];
    officeOptions.push(...officesSlice.map(office => {
        return {label: office.name, value: office.id};
    }));

    function applyFilters() {
        setFilters?.((filters: any) => {
            return {
                ...filters,
                customer: filtersRef.current?.customer,
                office  : filtersRef.current?.office,
                sku     : filtersRef.current?.sku,
            }
        });
        setOpen?.(false);
    }

    return (
        <Modal openModal={open}
               widthSize='small'
               maxWidthSize='max-w-discount'
               customHeightSize='h-auto'
               overflowVisible={true}
               setOpenModalState={setOpen}>

                <BodyModal className='p-5'>
                    <section className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
                        <AsyncSelect
                            url="get-customer"
                            map={(item: any) => ({label: item.name, value: item.sapClient})}
                            name="customer"
                            label="Filtrar por Cliente"
                            defaultOption={filtersRef.current?.customer || undefined}
                            allOption={{label: 'Todos', value: ''}}
                            onSelectOption={option => {
                                filtersRef.current.customer = option;
                            }}
                            isDisabled={disabled}
                            size="md"
                        />
                        <Select
                            name={`office`}
                            label={`Filtrar por Sucursal`}
                            labelClassName={'text-pdlm-gray-1'}
                            singleValueColor={'#666666'}
                            value={filtersRef.current?.office?.value ?? -1}
                            isClearable={true}
                            options={officeOptions}
                            isSearchable={true}
                            isDisabled={disabled}
                            size="md"
                            onChange={option => {
                                filtersRef.current.office = option;
                            }}
                        />
                        <AsyncSelect
                            url="products/search-sku"
                            map={(item: any) => ({label: item, value: item})}
                            name="sku"
                            label="Filtrar por SKU"
                            defaultOption={filtersRef.current?.sku || undefined}
                            allOption={{label: 'Todos', value: ''}}
                            onSelectOption={option => {
                                filtersRef.current.sku = option;
                            }}
                            isDisabled={disabled}
                            size="md"
                        />
                    </section>
                    <div className={'w-full border-t mt-5'}>
                        <Button className="mx-auto mt-5" size={'lg'} children={'Aplicar'} onClick={applyFilters} />
                    </div>
                </BodyModal>
        </Modal>
    )
}
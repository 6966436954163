import {AxiosPromise} from 'axios';
import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

export const createOrder = (data): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/orders`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const payOrders = (data): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/orders/pay`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const sendOrderByEmail = (id, data) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/orders/${id}/send-email`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const getOrders = (page = null, office_id = null, search = null, saleStatus = null, dateFrom = null, searchColumn = null) => {
    let params: { [k: string]: any } = {};

    if (page) {
        params.page = page;
    }

    if (search) {
        params.search       = search;
        params.searchColumn = searchColumn;
    }

    if (saleStatus) {
        params.saleStatus = saleStatus;
    }

    if (dateFrom) {
        params.dateFrom = dateFrom;
    }

    if (office_id) {
        params.o = office_id;
    }

    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`/orders`, {
        params,
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const getOrderDetail = (code) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`/orders/${code}`, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const generateDocument = (code) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/orders/${code}/generate-doc`, {}, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const getInvoice = (code): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`/orders/${code}/get-invoice`, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

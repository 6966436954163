import Input from '../Form/Input/Input';
import {useForm} from 'react-hook-form';
import {useState} from 'react';
import {sendQuoteByEmail} from '../../../pages/api/quotes';
import Toast from '../../../helpers/toast';
import {ApproveIcon} from '../Icons';
import {sendOrderByEmail} from '../../../pages/api/orders';
import {sendSapOrderByEmail} from '../../../pages/api/metrics';
import {validate as isEmail} from 'isemail';

interface ISendQuoteDocument {
    orderId: number;
    isOrder?: boolean;
    sendOrderLabel?: string;
    sendQuoteLabel?: string;
    isFromMetrics?: boolean;
}

const SendQuoteOrderByEmail = ({
                                   orderId,
                                   isOrder = true,
                                   sendOrderLabel = 'Enviar comprobante de pago',
                                   sendQuoteLabel = 'Enviar Cotización',
                                   isFromMetrics = false,
                               }: ISendQuoteDocument) => {
    const {register, getValues, setValue} = useForm();
    const [isLoading, setIsLoading]       = useState(false);
    const [isSuccess, setIsSuccess]       = useState(false);
    const [email, setEmail]               = useState(null);

    const sendQuoteEmail = () => {
        const email = getValues('email');
        if (email && validateEmail(email)) {
            setIsLoading(true);
            setEmail(getValues('email'));
            if (isOrder) {
                sendOrderByEmail(orderId, {email: getValues('email')})
                    .then(r => {
                        const message = r?.data?.message || 'La cotización fue enviada exitosamente';
                        setIsSuccess(true);
                        Toast({message});
                    })
                    .catch((e) => {
                        const message = e.response?.data?.message || 'La cotización no pudo ser enviada, intente nuevamente.';
                        Toast({message, type: 'danger'});
                    })
                    .finally(() => setIsLoading(false));

            } else {
                isFromMetrics ?
                    sendSapOrderByEmail(orderId, {email: getValues('email')})
                        .then(r => {
                            const message = r?.data?.message || 'La cotización fue enviada exitosamente';
                            setIsSuccess(true);
                            Toast({message});
                        })
                        .catch((e) => {
                            const message = e.response?.data?.message || 'La cotización no pudo ser enviada, intente nuevamente.';
                            Toast({message, type: 'danger'});
                        })
                        .finally(() => setIsLoading(false))
                    :
                    sendQuoteByEmail(orderId, {email: getValues('email')})
                        .then(r => {
                            const message = r?.data?.message || 'La cotización fue enviada exitosamente';
                            setIsSuccess(true);
                            Toast({message});
                        })
                        .catch((e) => {
                            const message = e.response?.data?.message || 'La cotización no pudo ser enviada, intente nuevamente.';
                            Toast({message, type: 'danger'});
                        })
                        .finally(() => setIsLoading(false));
            }

        } else {
            Toast({message: 'Ingrese un correo electrónico válido.', type: 'warning'});
        }
    };

    const sendOrderEmail = () => {
        const email = getValues('email');
        if (email && validateEmail(email)) {
            setIsLoading(true);
            setEmail(getValues('email'));
            isFromMetrics ?
                sendSapOrderByEmail(orderId, {email: getValues('email')})
                    .then(r => {
                        const message = r?.data?.message || 'El pedido fue enviado exitosamente';
                        setIsSuccess(true);
                        Toast({message});
                    })
                    .catch((e) => {
                        const message = e.response?.data?.message || 'El pedido no pudo ser enviado, intente nuevamente.';
                        Toast({message, type: 'danger'});
                    })
                    .finally(() => setIsLoading(false))
                :
                sendOrderByEmail(orderId, {email: getValues('email')})
                    .then(r => {
                        const message = r?.data?.message || 'Comprobante de pago enviado exitosamente';
                        setIsSuccess(true);
                        Toast({message});
                    })
                    .catch((e) => {
                        const message = e.response?.data?.message || 'El Comprobante de pago no pudo ser enviado, intente nuevamente.';
                        Toast({message, type: 'danger'});
                    })
                    .finally(() => setIsLoading(false));
        } else {
            Toast({message: 'Ingrese un correo electrónico válido.', type: 'warning'});
        }
    };

    const validateEmail = (email) => {
        if (email) {
            return isEmail(email);
        }
    };

    return (
        <div className="p-5">
            {
                isSuccess ?
                    <div className="mb-5 text-center text-lg">
                        <div className="flex justify-center items-center space-x-2">
                            <ApproveIcon/>
                            <p className="text-pdlm-blue font-bold">
                                {
                                    isOrder ? <span>
                                        Pedido enviado con éxito a:
                                    </span>
                                        :
                                        <span>
                                            Cotización enviada con éxito a:
                                        </span>
                                }
                            </p>
                        </div>
                        <p>{email}</p>
                    </div>
                    : null
            }
            <Input label={isOrder ? sendOrderLabel : sendQuoteLabel}
                   name="email"
                   btnOutline={true}
                   required
                   register={register}
                   btnText={isSuccess ? 'Reenviar' : 'Enviar'}
                   btnIsLoading={isLoading}
                   setValue={setValue}
                   btnClickHandler={isOrder ? sendOrderEmail : sendQuoteEmail}
            />
        </div>
    );
};

export default SendQuoteOrderByEmail;

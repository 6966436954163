import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Select from '../../Select/Select';
import {IOption} from '../../../../interfaces/option';
import useId from '../../../../hooks/useId';

export interface ISearchInputProps {
    searchText?: any,
    placeholder?: string,
    onChange?: any,
    onClick?: any,
    label?: string,
    onKeyPress?: any,
    size?: 'sm' | 'base',
    widthInput?: string,
    containerClassName?: string,
    iconColor?: string,
    clearable?: boolean,
    select?: boolean,
    withoutFlexContainer?: boolean,
    selectOptions?: IOption[],

    onFocus?(e): void

    onClearable?(): void

    onChangeSelect?(value): void
}

const SearchInput = ({
                         onChange,
                         searchText = '',
                         placeholder,
                         onClick,
                         label,
                         onKeyPress,
                         size,
                         widthInput,
                         containerClassName = '',
                         iconColor = null,
                         clearable,
                         select,
                         selectOptions,
                         withoutFlexContainer,
                         onFocus,
                         onClearable,
                         onChangeSelect,
                     }: ISearchInputProps) => {
    const id = useId();
    const [value, setValue]             = useState('');
    const [selectValue, setSelectValue] = useState(null);

    useEffect(() => {
        setValue(searchText);
    }, [searchText]);

    useEffect(() => {
        select && setSelectValue(selectOptions[0].value);
    }, []);


    let bPY;
    let iPY;
    let wInput = widthInput ? widthInput : 'w-72';
    switch (size) {
        case 'sm':
            bPY = 'py-1';
            iPY = 'py-1';
            break;
        default:
            bPY = 'py-2';
    }

    const handleClear = () => {
        setValue('');
        onChange ? onChange('') : null;
        onClearable ? onClearable() : null;
    };

    const handleOnChange = (event) => {
        setValue(event.target.value);
        return onChange ? onChange(event.target.value) : null;
    };

    const handleOnChangeSelect = ({value}) => {
        setSelectValue(value);
        return onChangeSelect ? onChangeSelect(value) : null;
    };

    return (
        <div className={`${withoutFlexContainer ? 'w-full' : 'flex flex-col'} ${containerClassName}`}>
            {
                label ? <label htmlFor={id} className="text-pdlm-gray-1 text-xs ml-1 mb-1">
                    {label}
                </label> : null
            }
            <div className="flex border border-pdlm-gray-3 bg-white rounded">
                {
                    select ?
                        <Select options={selectOptions} defaultValue={selectValue} name="select-search-input" size="md"
                                withInput={true}
                                isSearchable={false} isClearable={false} onChange={handleOnChangeSelect}/> : null
                }
                <input
                    id={id}
                    className={`${wInput} ${iPY} text-gray-700 leading-tight border-0 placeholder-pdlm-gray-1 focus:ring-0 text-sm rounded`}
                    type="text"
                    onKeyUp={onKeyPress}
                    name="search"
                    autoComplete="off"
                    placeholder={placeholder || 'Buscar'}
                    onChange={handleOnChange}
                    value={value}
                    onFocus={onFocus ? (e) => onFocus(e) : null}/>
                {
                    clearable ?
                        <div className="w-4 flex justify-center items-center">
                            {
                                value.length > 0 ?
                                    <a className={`h-full cursor-pointer px-1 ${bPY} bg-white text-pdlm-gray-5 font-medium`}
                                       onClick={handleClear}>x</a> : null
                            }
                        </div> : null
                }

                <button className={`h-full m-auto border-0 px-4 rounded ${bPY} bg-white`} onClick={onClick}
                        type="submit">
                    <FontAwesomeIcon className={iconColor ? iconColor : 'text-pdlm-blue'} icon={faSearch}/>
                </button>
            </div>
        </div>
    );
};

export default SearchInput;

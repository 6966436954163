import NumberFormat from 'react-number-format';

interface IBillingTypeSummary {
    billingTypeSummary: any;
}

const BillingTypeSummaryTable = ({billingTypeSummary}: IBillingTypeSummary) => {

    return (
        <div className="text-sm w-full border border-pdlm-gray-3 rounded-md bg-white">
            {/*Headers*/}
            <div
                className="bg-pdlm-sky-blue-2 h-10 flex items-center font-medium text-pdlm-blue text-center rounded-t-md border-b border-pdlm-gray-3">
                <div className="w-1/4">
                    Ventas
                </div>
                <div className="w-1/4">
                    Monto
                </div>
                <div className="w-1/4">
                    Tickets
                </div>
                <div className="w-1/4">
                    Ticket Promedio
                </div>
            </div>
            {/*Body*/}
            {/*Ticket*/}
            <div
                className="h-9 flex items-center text-center text-sm text-pdlm-black">
                <div className="w-1/4 flex items-center pl-3 bg-pdlm-gray-4 self-stretch">
                    Boleta
                </div>
                <NumberFormat
                    prefix="$"
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    className="w-1/4 text-right pr-4"
                    value={billingTypeSummary?.ticket?.amount || 0}/>
                <div className="w-1/4">
                    {billingTypeSummary?.ticket?.qty || 0}
                </div>
                <NumberFormat
                    prefix="$"
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    className="w-1/4 text-right pr-4"
                    value={Math.round(billingTypeSummary?.ticket?.avg || 0)}/>
            </div>
            {/*Invoice*/}
            <div
                className="h-9 flex items-center text-center text-sm text-pdlm-black">
                <div className="w-1/4 flex items-center pl-3 bg-pdlm-gray-4 self-stretch">
                    Factura
                </div>
                <NumberFormat
                    prefix="$"
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    className="w-1/4 text-right pr-4"
                    value={billingTypeSummary?.invoice?.amount || 0}/>
                <div className="w-1/4">
                    {billingTypeSummary?.invoice?.qty || 0}
                </div>
                <NumberFormat
                    prefix="$"
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    className="w-1/4 text-right pr-4"
                    value={Math.round(billingTypeSummary?.invoice?.avg || 0)}/>
            </div>
            {/*Total*/}
            <div
                className="h-9 flex items-center text-center text-sm text-pdlm-black border-t border-b border-pdlm-gray-3 font-bold">
                <div className="w-1/4 flex items-center pl-3 bg-pdlm-gray-4 self-stretch">
                    Total
                </div>
                <NumberFormat
                    prefix="$"
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    className="w-1/4 text-right pr-4"
                    value={billingTypeSummary?.total?.amount || 0}/>
                <div className="w-1/4">
                    {billingTypeSummary?.total?.qty || 0}
                </div>
                <NumberFormat
                    prefix="$"
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    className="w-1/4 text-right pr-4"
                    value={Math.round(billingTypeSummary?.total?.avg || 0)}/>
            </div>
            {/*Quote*/}
            {/*<div*/}
            {/*    className="h-9 flex items-center text-center text-sm text-pdlm-black rounded-b-md">*/}
            {/*    <div*/}
            {/*        className="w-1/4 flex items-center pl-3 bg-pdlm-gray-4 self-stretch rounded-bl-md">*/}
            {/*        Cotización*/}
            {/*    </div>*/}
            {/*    <NumberFormat*/}
            {/*        prefix="$"*/}
            {/*        displayType="text"*/}
            {/*        decimalSeparator=","*/}
            {/*        thousandSeparator="."*/}
            {/*        className="w-1/4 text-right pr-4"*/}
            {/*        value={quoteSummary?.amount || 0}/>*/}
            {/*    <div className="w-1/4">*/}
            {/*        {quoteSummary?.qty || 0}*/}
            {/*    </div>*/}
            {/*    <NumberFormat*/}
            {/*        prefix="$"*/}
            {/*        displayType="text"*/}
            {/*        decimalSeparator=","*/}
            {/*        thousandSeparator="."*/}
            {/*        className="w-1/4 text-right pr-4"*/}
            {/*        value={Math.round(quoteSummary?.avg || 0)}/>*/}
            {/*</div>*/}
        </div>
    );
};

export default BillingTypeSummaryTable;
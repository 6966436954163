import {ReactNode} from 'react';
import {Dialog} from '@headlessui/react';
import classes from './Modal.module.scss';

interface IModal {
    children: ReactNode,
    openModal: boolean,
    setOpenModalState: any,
    refModal?: any,
    widthSize: 'small' | 'medium' | 'large',
    maxWidthSize?: string
    customHeightSize?: string
    overflowVisible?: boolean // set to true if there are dropdowns inside the modal
    className?: string
}

const Modal = ({
                   children,
                   openModal,
                   refModal,
                   widthSize,
                   setOpenModalState,
                   maxWidthSize = '',
                   customHeightSize = '',
                   overflowVisible,
                   className,
               }: IModal) => {

    const checkWidthSize = (widthSize: string) => {
        switch (widthSize) {
            case 'small':
                return classes.contentSmall;
            case 'medium':
                return classes.contentMedium;
            case 'large':
                return classes.contentLarge;
        }
    };

    const height = customHeightSize ? customHeightSize : classes.heightModal;

    return (
        <Dialog
            initialFocus={refModal}
            open={openModal}
            className={`fixed z-20 inset-0 ${className??''}`}
            onClose={() => setOpenModalState ? setOpenModalState(false) : null}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" onClick={null}/>
            <div className="flex items-center justify-center h-screen">
                <div className={`${checkWidthSize(widthSize)} ${maxWidthSize} ${height}`} style={overflowVisible ? {overflow: 'visible'} : {}}>
                    {children}
                </div>
            </div>
        </Dialog>
    );
};


export default Modal;

import {ReactNode} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

interface IHeaderModal {
    children: ReactNode,
    className?: string,
    setOpenModalState: any,
    displayCloseIcon?: boolean,
}

const HeaderModal = ({children, className = '', displayCloseIcon = true, setOpenModalState}: IHeaderModal) => {
    return (
        <div className={`p-4 flex justify-between ${className}`}>
            <div className="font-medium text-lg text-center w-full">{children}</div>
            <button className="focus:outline-none" onClick={() => setOpenModalState(false)}>
                {displayCloseIcon ? <FontAwesomeIcon icon={faTimes}/> : null}
            </button>
        </div>
    );
};

export default HeaderModal;

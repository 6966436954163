import {useEffect, useState} from 'react';
import {useMegaMenu} from '../../contexts/MegaMenuProvider';
import MegaMenuSection from './MegaMenuSection';
import {Category} from '../../interfaces';
import useOnclickOutside from 'react-cool-onclickoutside';

type Props = {
    initCategory: Category
}

const MegaMenu = ({initCategory}: Props) => {

    const [sections, setSections] = useState<Category[]>();
    const {setInitialCategory}    = useMegaMenu();
    const innerBorderRef          = useOnclickOutside(() => setInitialCategory(undefined));

    useEffect(() => {
        setSections([initCategory]);
    }, [initCategory]);

    const addSection = (category: Category, deepLevel: number) => {
        const baseSection = sections?.slice(0, deepLevel + 1);
        setSections([...baseSection!, category]);
    };

    const removeLastSection = (deepLevel: number) => {
        const baseSection = sections?.slice(0, deepLevel + 1);
        setSections([...baseSection!]);
    };

    const handleCloseMegaMenu = () => {
        setInitialCategory(undefined);
    };

    return (
        <>
            <div className="mega-menu inset-0 z-30 bg-black bg-opacity-70 top-0" onClick={handleCloseMegaMenu} />
            <div className="mega-menu bottom-0 top-20 left-0 bg-white z-40 border flex flex-row overflow-x-auto"
                style={{maxWidth: "calc(100vw - 100px)"}}
                ref={innerBorderRef}>
                {
                    sections?.map((section, index) =>
                        <MegaMenuSection
                            categories={section.children}
                            deepLevel={index}
                            addSection={addSection}
                            removeLastSection={removeLastSection}
                            sections={sections}
                            key={index}/>)
                }
            </div>
        </>
    );
};

export default MegaMenu;

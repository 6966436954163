import c from './CategoryButton.module.scss';

import {
    AgricolaIcon,
    ContencionIcon,
    HidraulicaIcon,
    ConstruccionIcon,
    CabosCadenasIcon,
    AlambresCercosIcon,
    AcerosMetalurgiaIcon,
    ClavosFijacionesIcon,
    SeguridadPerimetralIcon,
    SeguridadelEctronicaIcon,
} from '../../ui/Icons';
import {Category} from '../../../interfaces';
import {removeAllFilters} from '../../../redux/slices/filters.slice';
import {useAppDispatch} from '../../../hooks/redux';
import {useRouter} from 'next/router';
import {resetProducts} from '../../../redux/slices/products.slice';

interface ICategoryButton {
    category?: Category,
    onClick?: any,
    isHeader?: boolean,
    isSkeleton?: boolean,
}

const CategoryButton = ({category, isHeader, onClick, isSkeleton = false}: ICategoryButton) => {
    const dispatch = useAppDispatch();
    const router   = useRouter();

    const restoreFilters = () => {
        dispatch(removeAllFilters());
        dispatch(resetProducts());
        router.replace({
                pathname: '/',
                query: {},
            },
            undefined,
            {
                shallow: true,
            },
        );
    };
    if (isHeader) {
        return (
            <div className={`${c.btn} cursor-pointer`} onClick={() => restoreFilters()}>
                <p className={c.label}>Categorías</p>
            </div>
        );
    }

    if (isSkeleton) {
        return (
            <div className={`${c.btn} ${c.normal} cursor-wait`}>
                <div className="animate-pulse flex flex-col justify-around items-center transition duration-75">
                    <div className="w-6 h-6 bg-pdlm-gray-3 rounded"/>
                    <div className="w-10 mt-2 h-2 bg-pdlm-gray-3 rounded"/>
                </div>
            </div>
        );
    }

    let icon = null;
    switch (category?.menu_id) {
        case 1:
            icon = <AcerosMetalurgiaIcon/>;
            break;
        case 2:
            icon = <AgricolaIcon/>;
            break;
        case 3:
            icon = <AlambresCercosIcon/>;
            break;
        case 4:
            icon = <ClavosFijacionesIcon/>;
            break;
        case 5:
            icon = <ConstruccionIcon/>;
            break;
        case 6:
            icon = <ContencionIcon/>;
            break;
        case 7:
            icon = <HidraulicaIcon/>;
            break;
        case 8:
            icon = <SeguridadelEctronicaIcon/>;
            break;
        case 9:
            icon = <SeguridadPerimetralIcon/>;
            break;
        case 10:
            icon = <CabosCadenasIcon/>;
            break;
    }

    const onClickHandler = () => {
        onClick(category);
    };

    return (
        <button className={`${c.btn} ${c.normal}`} onClick={onClickHandler}>
            {icon}
            <p className={c.labelIcon}>{category?.label}</p>
        </button>
    );
};

export default CategoryButton;

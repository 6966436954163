import {findAll} from 'highlight-words-core';

interface IHighlighter {
    textToHighlight: string,
    searchWords: string[]
    activeClassName?: string,
    className?: string,
}

const Highlighter = ({textToHighlight, searchWords, activeClassName = 'font-bold', className}: IHighlighter) => {
    if (searchWords.length === 0) {
        return <span>{textToHighlight}</span>;
    }
    textToHighlight = String(textToHighlight);

    const chunks = findAll({
        searchWords,
        textToHighlight
    });

    return <span className={className}>{
        chunks.map((chunk, i) => {
            const {end, highlight, start} = chunk;
            const text                    = textToHighlight.substr(start, end - start);
            if (highlight) {
                return <span className={activeClassName} key={i}>{text}</span>;
            } else {
                return <span key={i}>{text}</span>;
            }
        })
    }</span>;

};

export default Highlighter;

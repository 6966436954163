import ProductImage from '../../ProductCard/ProductImage';
import NumberFormat from 'react-number-format';

const ProductTable = ({orderQuoteData}) => {
    return (
        <div className={'flex flex-col w-full border rounded'}>
            <div className={'font-bold text-pdlm-blue w-full bg-pdlm-sky-blue-2 py-4 px-8'}>
                Productos
            </div>
            <div className={'px-8'}>
                {
                    orderQuoteData?.products.map((p, i) =>
                        <div className={'flex border-b py-4'} key={i}>
                            <div className="flex flex-col w-36 justify-center">
                                <ProductImage src={p.image} marginAuto={true}/>
                            </div>
                            <div className={'flex flex-col w-full text-sm'}>
                                <div className="flex">
                                    <p className={`productTitle font-bold mr-2`}>{p.title}</p>
                                    <div className={'flex flex-col items-end ml-auto'}>
                                        <div className="text-pdlm-blue font-bold ml-auto">
                                            <NumberFormat displayType="text" thousandSeparator="."
                                                          decimalSeparator="," prefix="$"
                                                          value={Math.round(p.total_with_discount)}
                                            />
                                        </div>
                                        <div className="text-pdlm-gray-5 font-bold text-sm ml-auto">
                                            {
                                                p.discount !== 0 &&
                                                <NumberFormat displayType="text"
                                                              thousandSeparator="."
                                                              decimalSeparator=","
                                                              prefix={(p.discount>0 ? '-' : '+') + "$"}
                                                              value={Math.round(Math.abs(p.discount))}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'flex'}>
                                    <div className="flex items-center justify-between w-full">
                                        <div className="flex flex-col">
                                            <p className="text-pdlm-black">
                                                <span className="font-bold">SKU:</span> {p.code}
                                            </p>
                                            <p className="font-medium text-base flex flex-row text-pdlm-gray-1 items-center">
                                                {/*{priceDescription}*/}
                                                <span className="price text-base font-medium">
                                                    <NumberFormat displayType="text"
                                                                  thousandSeparator="."
                                                                  decimalSeparator=","
                                                                  prefix="$"
                                                                  value={Math.round(p.price)}/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'flex justify-end items-end w-20'}>
                                        <div className={'ml-auto'}>Cant: {p.qty}</div>
                                    </div>
                                </div>
                            </div>
                        </div>,
                    )
                }
            </div>
            <div className={'flex flex-col text-sm bg-pdlm-gray-4 py-4'}>
                <div className={'flex flex-row font-bold justify-between items-center h-7 px-6'}>
                    <div>Subtotal</div>
                    <div>
                        <NumberFormat displayType="text"
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix="$"
                                      value={orderQuoteData?.paymentSummary.subtotal}/>
                    </div>
                </div>
                <div className={'flex flex-row justify-between items-center h-7 px-6'}>
                    {
                        orderQuoteData?.delivery?.isDelivery ?
                            <div>Despacho:</div>
                            : <div>Retiro en Tienda:</div>

                    }
                    {orderQuoteData?.delivery?.isDelivery ?
                        <div>
                            <NumberFormat displayType="text" thousandSeparator="."
                                          decimalSeparator="," prefix="$"
                                          value={orderQuoteData?.paymentSummary.deliveryPrice}/>
                        </div>
                        :
                        <div className="flex justify-between">
                            <p>Gratis</p>
                        </div>
                    }
                </div>
                <div className={'flex flex-row justify-between items-center h-7 px-6'}>
                    <div>Descuentos:</div>
                    <div>
                        <NumberFormat displayType="text"
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix="$"
                                      value={orderQuoteData?.paymentSummary.discount}/>
                    </div>
                </div>
                <div
                    className={'flex flex-row text-white font-bold justify-between items-center bg-pdlm-blue-1 h-7 px-6'}>
                    <div>Total:</div>
                    <div>
                        <NumberFormat displayType="text"
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix="$"
                                      value={orderQuoteData?.paymentSummary.total}/>
                    </div>
                </div>
                <div className={'flex flex-row justify-between items-center h-7 px-6'}>
                    <div>Total sin IVA:</div>
                    <div>
                        <NumberFormat displayType="text"
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix="$"
                                      value={orderQuoteData?.paymentSummary.totalWithoutTax}/>
                    </div>
                </div>
                <div className={'flex flex-row justify-between items-center h-7 px-6'}>
                    <div>IVA 19%:</div>
                    <div>
                        <NumberFormat displayType="text"
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix="$"
                                      value={orderQuoteData?.paymentSummary.tax}/>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ProductTable;
import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

export const cashRegisterInformation = (office_id, date, cash_register_id?) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`metrics/cash-register-information`, {
        params: {
            office_id,
            date,
            cash_register_id,
        },
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const getSaleDetail = (code) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`metrics/sale-details/${code}`, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const downloadCashRegisterInformation = (cash_register_id?) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`metrics/cash-register-information/export`,
        {
            cash_register_id,
        },
        {
            responseType: 'blob',
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            },
        });
};

export const getSalesSummary = (office_id, date) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

    return AxiosInstance.get(`metrics/sales-summary`,
        {
            params: {
                office_id,
                date,
            },
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
};

export const downloadSalesSummary = (office_id, date) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`metrics/sales-summary/export`,
        {
            'date': date,
            'office_id': office_id,
        },
        {
            responseType: 'blob',
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            },
        });
};

export const sendSapOrderByEmail = (sap_order, data) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/metrics/${sap_order}/send-email`, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const generateDocument = (code: string, type: 'COTIZACION' | 'VENTA', with_tax: boolean) => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(`/metrics/${code}/generate-doc`, {
        type, with_tax,
    }, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};
import {InputAlertIcon} from '../../Icons';
import c from './Input.module.scss';
import Button from '../../Button/Button';
import {format} from 'rut.js';
import {ReactChild, useEffect} from 'react';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Controller} from 'react-hook-form';
import NumberFormat from 'react-number-format';

interface IInput {
    label: string,
    name: string,
    placeholder?: string,
    type?: 'text' | 'email' | 'tel' | 'number'
    register?: any,
    control?: any,
    errors?: any,
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
    defaultValue?: any,
    prefix?: any,
    btnText?: string,
    btnClickHandler?: any,
    readonly?: boolean,
    required?: any,
    isRut?: boolean,
    value?: any,
    btnOutline?: boolean,
    labelClassName?: string
    btnType?: 'button' | 'submit' | 'reset'
    btnIsLoading?: boolean,
    formatNumber?: boolean
    validate?: {},
    validateMessage?: string,
    suffixButton?: ReactChild
    setValue: any,
    clearable?: boolean,
    clearableDefaultValue?: any,
    showMessageError?: boolean

    onFocus?(e): void,

    onKeyDown?(e): void,

    autoFocus?: boolean,
    borderColorOnError?: string,
    borderColor?: string,
    alertIcon?: any
    borderWith?: string
}

const Input = ({
                   type = 'text',
                   label,
                   name,
                   placeholder,
                   register,
                   errors,
                   defaultValue,
                   size = 'base',
                   prefix,
                   btnText,
                   btnClickHandler,
                   btnOutline = false,
                   readonly = false,
                   required,
                   value,
                   isRut = false,
                   labelClassName = '',
                   btnType = 'button',
                   btnIsLoading = false,
                   formatNumber = false,
                   control,
                   validate,
                   validateMessage,
                   suffixButton,
                   setValue,
                   clearable = false,
                   clearableDefaultValue = null,
                   onFocus,
                   onKeyDown,
                   showMessageError = true,
                   autoFocus = false,
                   borderColorOnError,
                   borderColor,
                   alertIcon = null,
                   borderWith = 'border',
               }: IInput) => {
    let sizeClass    = '';
    let paddingClass = '';
    switch (size) {
        case 'xs':
            sizeClass = 'h-5';
            break;
        case 'sm':
            sizeClass = c.sm;
            break;
        case 'base':
            sizeClass    = 'h-4';
            paddingClass = 'p-1 pl-2';
            break;
        case 'lg':
            sizeClass = 'h-9';
            break;
        case 'xl':
            sizeClass = 'h-10';
            break;
    }
    const readOnlyStyle = readonly ? 'bg-pdlm-gray-4' : '';
    register            = register ? {...register(name, {required, validate})} : register;
    control             = control ? {...control} : control;

    useEffect(() => {
        if (value) {
            setValue(name, value);
        }
    }, []);

    const onChange = (e) => {
        if (isRut && e.target.value && e.target.value !== '') {
            setValue(name, format(e.target.value));
        } else {
            setValue(name, e.target.value);
        }
    };

    const onClickClearable = () => {
        if (clearableDefaultValue != null) {
            setValue(name, clearableDefaultValue);
        }
    };

    const clearableButton = (
        <div className="w-4 flex justify-center items-center">
            {
                <a className={`h-full cursor-pointer pr-2 bg-white text-pdlm-gray-5 font-medium`}
                   onClick={onClickClearable}>x</a>
            }
        </div>
    );

    return (
        <div className="flex flex-col w-full">
            {
                label ? <label htmlFor={name} className={`${labelClassName || ''} text-xs mb-1 ml-1`}>
                    {label}{required ? '*' : ''}
                </label> : null
            }

            <div
                className={`w-full flex ${borderWith} rounded items-center border ${errors ? (borderColorOnError ?? 'border-pdlm-red-1') : (borderColor ?? 'border-pdlm-gray-3')}`}>
                {
                    prefix ?
                        <div
                            className={`bg-pdlm-gray-4 w-14 rounded-l flex items-center justify-center py-1 ${paddingClass}`}>
                            {prefix}
                        </div> : null
                }
                {
                    formatNumber ?
                        <Controller
                            control={control}
                            defaultValue={null}
                            name={name}
                            rules={{required: required}}
                            render={({field: {onChange, name, value}}) => (
                                <>
                                    <NumberFormat thousandSeparator="."
                                                  name={name}
                                                  id={name}
                                                  readOnly={readonly}
                                                  allowNegative={false}
                                                  decimalSeparator=","
                                                  required={required}
                                                  className={`text-gray-700 rounded leading-tight border-0 placeholder-pdlm-gray-3 focus:ring-0 w-full ${paddingClass} ${sizeClass} ${readOnlyStyle}`}
                                                  prefix={prefix ? '' : '$'}
                                                  placeholder={placeholder || ''}
                                                  defaultValue={defaultValue}
                                                  disabled={btnIsLoading}
                                                  onValueChange={(v) => onChange(v.value)}
                                                  value={value}
                                                  autoFocus={autoFocus}
                                                  onKeyDown={onKeyDown}
                                                  onFocus={onFocus ? (e) => onFocus(e) : null}
                                    />
                                    {
                                        clearable ? clearableButton : null
                                    }
                                </>

                            )}
                        />
                        :
                        <>
                            <input
                                id={name}
                                {...register}
                                readOnly={readonly}
                                type={type}
                                onChange={(e) => onChange(e)}
                                className={`text-gray-700 rounded leading-tight border-0 placeholder-pdlm-gray-3 focus:ring-0 w-full ${paddingClass} ${sizeClass} ${readOnlyStyle}`}
                                placeholder={placeholder || ''}
                                defaultValue={defaultValue}
                                disabled={btnIsLoading}
                                autoFocus={autoFocus}
                                onKeyDown={onKeyDown}
                                onFocus={onFocus ? (e) => onFocus(e) : null}
                            />
                            {
                                clearable ? clearableButton : null
                            }
                        </>

                }
                {
                    suffixButton ?
                        <>
                            {
                                suffixButton
                            }
                        </>
                        : null
                }
                {
                    errors && !suffixButton ?
                        <div className="mr-1">
                            {alertIcon ? alertIcon : <InputAlertIcon/>}
                        </div> : null
                }
                {/*Button*/}
                {
                    btnText ?
                        <Button className="rounded-l-none font-normal" size="sm"
                                onClick={btnIsLoading ? null : btnClickHandler}
                                outline={btnOutline}
                                type={btnType}
                                children={btnIsLoading ?
                                    <FontAwesomeIcon className="text-pdlm-blue animate-spin"
                                                     icon={faCircleNotch}/> : btnText}/>
                        : null
                }
            </div>
            {/*Required validation*/}
            {
                (errors?.type === 'required' && showMessageError) &&
                <p className="text-xs mt-1 ml-1 text-pdlm-red-1">{`${label} es obligatorio`}</p>
            }
            {/*Regex validation*/}
            {
                errors?.type === 'pattern' &&
                <p className="text-xs mt-1 ml-1 text-pdlm-red-1"> Por favor, ingresa un correo electrónico válido</p>
            }
            {/*Regex validation*/}
            {
                (errors?.type === 'validate' && validateMessage) &&
                <p className="text-xs mt-1 ml-1 text-pdlm-red-1">{validateMessage}</p>
            }
        </div>
    );
};

export default Input;

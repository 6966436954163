import {useEffect, useState} from 'react';
import {Category} from '../../interfaces';
import {useMegaMenu} from '../../contexts/MegaMenuProvider';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {setCurrentCategory} from '../../redux/slices/categories.slice';
import {useRouter} from 'next/router';
import {onChangeCategory, removeAllFiltersExceptStock} from '../../redux/slices/filters.slice';
import {TypeFilter} from '../../models/filter-option.model';

export interface MegaMenuSectionProps {
    categories: Category[];
    deepLevel: number,
    addSection: (category: Category, deepLevel: number) => void,
    removeLastSection: (deepLevel: number) => void,
    sections: Category[]
}

const MegaMenuSection = ({categories, deepLevel, addSection, removeLastSection, sections}: MegaMenuSectionProps) => {
    const router                          = useRouter();
    const {setInitialCategory}            = useMegaMenu();
    const [selectOption, setSelectOption] = useState<Category>(null);
    const dispatch                        = useAppDispatch();
    const filters                         = useAppSelector(state => state.filters);

    useEffect(() => {
    }, [selectOption]);

    const handleMouseEnter = (category: Category) => {
        setSelectOption(category);
        if (category.children.length > 0) {
            addSection(category, deepLevel);
        } else {
            removeLastSection(deepLevel);
        }
    };

    const handleSelectCategory = (category: Category) => {
        const stockFilter = filters.filters?.find(filter => filter !== undefined && filter !== null && filter.type === TypeFilter.TYPE_STOCK && filter.value === 'STOCK_STORE');
        dispatch(removeAllFiltersExceptStock({keepStockStoreFilter: !!stockFilter}));

        if (router.pathname === '/') {
            dispatch(onChangeCategory({category}));
        } else {
            router.push({
                pathname: '/',
                query: {
                    cat: category.id,
                    ...(stockFilter && {stock: stockFilter.value}),
                },
            });
        }
        dispatch(setCurrentCategory({category}));
        setInitialCategory(undefined);
    };

    const selectAll = () => handleSelectCategory(sections[deepLevel]);

    const searchCategory = (category: Category, id: number) => {
        let parentCategory = null;
        for (let i = 0; i < category.children.length; i++) {
            parentCategory = category.children[i].id === id ? category.children[i] : searchCategory(category.children[i], id);
            if (parentCategory) {
                break;
            }
        }
        return parentCategory;
    };

    const handleSelectAllMouseEnter = () => {
        setSelectOption(null);
        removeLastSection(deepLevel);
    };

    return (
        <div className="border-collapse border w-72 z-40">
            <ul className="h-full overflow-y-auto">
                {categories?.map((category: Category, index) => (
                    <span key={index}>
                        {
                            index === 0 ?
                                <div
                                    className="font-bold py-2 px-4 text-xs border-b h-12 text-center flex items-center cursor-pointer hover:bg-pdlm-sky-blue-hover"
                                    onMouseEnter={() => handleSelectAllMouseEnter()}
                                    onClick={() => selectAll()}>
                                    <p>Ver Todo</p>
                                </div>
                                : null
                        }
                        <li
                            className={`h-12 px-4 text-xs items-center flex justify-between cursor-pointer ${selectOption?.id === category.id ? 'bg-pdlm-sky-blue-hover' : ''}`}
                            onMouseEnter={() => handleMouseEnter(category)}
                            onClick={() => handleSelectCategory(category)}
                        >
                            <p className="mr-5">{category.label}</p>
                            <div>
                                {
                                    category?.children?.length > 0 ?
                                        <i className="fa fa-chevron-right" aria-hidden="true"/> : null
                                }
                            </div>
                        </li>
                    </span>
                ))}
            </ul>
        </div>
    );
};

export default MegaMenuSection;

import Modal from '../ui/Modal/Modal';
import {useEffect, useRef, useState} from 'react';
import BodyModal from '../ui/Modal/BodyModal';
import HeaderModal from '../ui/Modal/HeaderModal';
import {Tab} from '@headlessui/react';
import Button from '../ui/Button/Button';

// Components tabs
import CashRegisterInformation from './CashRegisterInformation';
import SalesDetails from './SalesDetails/SalesDetails';
import SalesSummary from './SalesSummary';

interface IMetrics {
    open: boolean,
    setOpen: any
}

const Metrics = (props: IMetrics) => {
    const ref                 = useRef();
    const bodyRef             = useRef();
    const [height, setHeight] = useState(0);

    useEffect(() => {
        // @ts-ignore
        setHeight(bodyRef?.current?.clientHeight);
    }, [bodyRef?.current]);

    const tabs = [
        {
            id: 1,
            name: 'Resumen de Ventas',
            borderUnselect: 'border-r border-b',
        },
        {
            id: 2,
            name: 'Información de Cajas',
            borderUnselect: 'border-r border-l border-b',
        },
        {
            id: 3,
            name: 'Detalle de Ventas',
            borderUnselect: 'border-l border-b',
        },
    ];

    const handleClose = () => props.setOpen(false);

    return (
        <Modal openModal={props.open}
               refModal={ref}
               widthSize={'medium'}
               maxWidthSize={'max-w-discount'}
               setOpenModalState={props.setOpen}>
            <HeaderModal setOpenModalState={props.setOpen} className="text-pdlm-blue">
                <p>Resumen de Ventas y Caja</p>
            </HeaderModal>
            <BodyModal ref={bodyRef} className={'border-t'}>
                <div className={'flex flex-col w-full overflow-y-hidden'} style={{height: height + 'px'}}>
                    <Tab.Group>
                        <Tab.List as={'div'}>
                            {
                                tabs.map(tab =>
                                    (
                                        <Tab
                                            key={tab.id}
                                            className={({selected}) =>
                                                `w-1/3 h-12 text-sm ${!selected ? 'bg-pdlm-gray-4 text-pdlm-black' : 'font-bold text-pdlm-blue'}
                                                 ${!selected && tab.borderUnselect}`
                                            }>
                                            {tab.name}
                                        </Tab>
                                    ),
                                )
                            }
                        </Tab.List>
                        <Tab.Panels className={'flex h-full w-full overflow-y-hidden'}>
                            <Tab.Panel className={'flex h-full w-full'}>
                                <SalesSummary/>
                            </Tab.Panel>
                            <Tab.Panel className={'flex h-full w-full'}>
                                <CashRegisterInformation/>
                            </Tab.Panel>
                            <Tab.Panel className={'flex h-full w-full'}>
                                <SalesDetails/>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                    <div className={'flex items-center justify-center w-full py-3 border-t px-13'}>
                        <Button size={'lg'} children={'Cerrar'} onClick={handleClose}/>
                    </div>
                </div>
            </BodyModal>
        </Modal>
    );
};

export default Metrics;

import c from './Button.module.scss';
import {ReactNode} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';

interface IButton {
    children: ReactNode,
    size?: 'sm' | 'lg' | 'block' | string,
    outline?: boolean,
    onClick?: any,
    className?: string,
    prefixIcon?: ReactNode
    disabled?: boolean,
    type?: 'button' | 'submit' | 'reset',
    btnFilter?: boolean
    btnBackgroundClass?: string,
    outlineSky?: boolean,
    isLoading?: boolean,
}

const Button = ({
                    prefixIcon,
                    children,
                    size,
                    outline = false,
                    onClick,
                    className,
                    type = 'button',
                    disabled = false,
                    btnFilter,
                    btnBackgroundClass,
                    outlineSky = false,
                    isLoading = false
                }: IButton) => {
    let btnClasses = `${c.btn} rounded ${btnBackgroundClass ? btnBackgroundClass : 'bg-pdlm-blue'}`;
    if (size === 'sm') {
        btnClasses += ` ${c.btnSm}`;
    } else if (size === 'lg') {
        btnClasses += ` ${c.btnLg}`;
    } else if (size === 'block') {
        btnClasses += ` ${c.btnBlock}`;
    } else {
        btnClasses += ` ${c.btnBase}`;
    }
    if (disabled) {
        onClick = () => {
            return;
        };
    }
    if (outline) {
        btnClasses = `${btnClasses} ${c.btnOutline}`;
    } else if (outlineSky) {
        btnClasses = `${btnClasses} ${c.btnOutlineSky}`;
    }
    if (btnFilter) {
        btnClasses = c.btnFilter;
    }
    if (className) {
        btnClasses = `${btnClasses} ${className}`;
    }
    return (
        <button className={btnClasses} onClick={onClick} type={type} disabled={disabled}
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
        >
            {prefixIcon ? <div className="mr-2">{prefixIcon}</div> : null}
            {isLoading ?
                <FontAwesomeIcon className="text-pdlm-blue animate-spin" icon={faCircleNotch}/> : children}
        </button>
    );
};

export default Button;

import {ReactNode, useEffect} from 'react';
import Head from 'next/head';
import Navbar from './NavBar';
import SideNav from './SideNav/SideNav';
import Content from './Content';
import {useMegaMenu} from '../contexts/MegaMenuProvider';
import MegaMenu from './mega-menu/MegaMenu';
import Loader from './Loader';
import {useAppSelector} from '../hooks/redux';

type Props = {
    title?: string,
    children?: ReactNode,
}

const Layout = ({title, children}: Props) => {
    const {initialCategory} = useMegaMenu();
    const loading           = useAppSelector((state) => state.loading);

    useEffect(() => {
    }, [initialCategory]);

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="version-info" content={process.env.NEXT_PUBLIC_VERSION_INFO}/>
                <script type="text/javascript" src="/vendor/orbitvu/orbitvu.js"/>
                <script type="text/javascript" src="/vendor/orbitvu/custom.js"/>
            </Head>
            {loading.isLoading ? <Loader/> : null}
            <div className="h-full flex flex-col">
                <Navbar/>
                <div className="flex items-stretch overflow-y-scroll h-full w-full no-scroll-bar">
                    <SideNav/>
                    <div className="relative w-full">
                        {initialCategory ? <MegaMenu initCategory={initialCategory}/> : null}
                        <Content>
                            {children}
                        </Content>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;

import { useState } from 'react';
import Select, { IOption, ISelect } from './Select';
import useSWR from 'swr';
import {components} from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useDebounce } from '../../../hooks/useDebounce';
import { fetcher } from '../../../pages/api/products';
import Utils from '../../../utils/utils';

type AsyncSelectProps = {
    url: string
    map: (item: any) => IOption
    allOption?: { label: string, value: any }
    onSelectOption: (option: IOption | null) => void
    defaultOption?: IOption
} & ISelect;

const Control = ({ children, ...props }: any) => (
    <components.Control {...props}>
        <FontAwesomeIcon className="text-pdlm-blue ml-3" icon={faSearch} width={14}/>
        {children}
    </components.Control>
);

export default function AsyncSelect({url, map, allOption, onSelectOption, defaultOption, ...props}: AsyncSelectProps) {
    const [search, setSearch] = useState('');
    const searchDebounce      = useDebounce(search, 300);
    const _url                = searchDebounce ? url + '?' + Utils.getStrParams({q: searchDebounce}) : null;
    const {data, isLoading}   = useSWR(_url, fetcher(),  {
        revalidateOnFocus: false,
        revalidateOnMount: false,
    });

    function onInputChange(value: any, action: any) {
        if(action.action === 'input-change') {
            setSearch(value);
        }
    }

    const options: IOption[] = [];
    if(allOption) {
        options.push(allOption);
    }
    if(data?.data) {
        options.push(...data.data.map(map));
    }
    if(defaultOption && (data?.data?.length ?? 0) === 0) {
        options.push(defaultOption);
    }

    return (
        <Select
            labelClassName={'text-pdlm-gray-1'}
            singleValueColor={'#666666'}
            isSearchable={true}
            isClearable={true}
            isLoading={isLoading}
            onInputChange={onInputChange}
            options={options}
            value={defaultOption?.value ?? ''}
            onChange={selected => {
                const option = options.find(option => option.value === selected?.value);
                onSelectOption(option ?? null)
            }}
            filterOption={() => true}
            components={{ Control }}
            {...props}
        />
    )
}

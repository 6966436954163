import {Fragment, ReactNode} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import Button from '../../ui/Button/Button';

interface IConfirmAlertModalProps {
    title: string,
    content: ReactNode,
    confirm: () => void,
    cancel: () => void,
    openAlert: boolean,
    textConfirmButton: string,
    textCancelButton: string,
    zIndex?: string
}

const ConfirmAlertModal = ({
                               title,
                               content,
                               confirm,
                               cancel,
                               openAlert,
                               textConfirmButton,
                               textCancelButton,
                               zIndex = "z-10"
                           }: IConfirmAlertModalProps) => {
    return (
        <Transition appear show={openAlert} as={Fragment}>
            <Dialog
                as="div"
                className={`fixed inset-0 ${zIndex} overflow-y-auto`}
                onClose={() => 1}
            >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0"/>
                    </Transition.Child>

                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                      &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                                {
                                    title
                                }
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {
                                        content
                                    }
                                </p>
                            </div>

                            <div className="flex justify-center space-x-4 items-center mt-4">
                                <Button children={textCancelButton} onClick={cancel} type={'button'}
                                        size={'sm'} outline={true}/>
                                <Button children={textConfirmButton} onClick={confirm} type={'submit'}
                                        size={'sm'}/>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ConfirmAlertModal;

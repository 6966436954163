const PrinterSelectionSkeleton = () => {

    return (
        <div className={'flex flex-col w-full h-full py-10 px-11 overflow-y-auto custom-scrollbar space-y-4'}>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
            <div className="h-14 bg-pdlm-gray-3 rounded-sm"/>
        </div>
    );
};

export default PrinterSelectionSkeleton;

import {useContext, useState} from 'react';
import SearchInputNav from './SearchInputNav';
import MenuButton from './ui/MenuButton/MenuButton';
import OfficeSelect from './OfficeSelect/OfficeSelect';
import {AuthContext} from '../contexts/AuthProvider';
import {LogoWhite, UserMenuIcon} from './ui/Icons';
import {faAngleDown, faBars} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAppDispatch, useAppSelector} from '../hooks/redux';
import {handleFilterByText, removeAllFiltersExceptStocks} from '../redux/slices/filters.slice';
import useOnclickOutside from 'react-cool-onclickoutside';
import {useRouter} from 'next/router';
import {resetProducts} from '../redux/slices/products.slice';
import {FilterOptionModel, TypeFilter} from '../models/filter-option.model';
import CashRegister from './CashRegister/CashRegister';
import {removeCurrentCategory} from '../redux/slices/categories.slice';
import {SupportIcon} from './ui/Support/SupportIcon';
import Support from './Support/Support';

const Navbar = () => {
    const dispatch                              = useAppDispatch();
    const router                                = useRouter();
    const authContext                           = useContext(AuthContext);
    const user                                  = authContext.user;
    const [active, setActive]                   = useState(false);
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const [isOpenMetrics, setIsOpenSupport]     = useState(false);
    const innerBorderRef                        = useOnclickOutside(() => setShowAccountMenu(false));
    const filtersSlice                          = useAppSelector(state => state.filters);
    const versionHeader: any                    = document?.head?.querySelector("[name=version-info]");

    const handleClick        = () => {
        setActive(!active);
    };
    const handleCloseSession = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const response = await authContext.logout();
        if (response.status !== 200) {
            return;
        }
        window.location.href = '/login';
    };

    const handleFilter = (text: string) => {
        const stockStore: FilterOptionModel = filtersSlice.filters.find(filter => filter.type === TypeFilter.TYPE_STOCK && filter.value === 'STOCK_STORE');

        if (router.pathname !== '/') {
            router.push({
                pathname: '/',
                query: {
                    q: text,
                    ...(stockStore ? {stock: stockStore.value} : {}),
                },
            });
        } else {
            dispatch(handleFilterByText(text));
        }
    };

    const handleShowAccountList = () => {
        setShowAccountMenu(!showAccountMenu);
    };

    const restoreFilters = () => {
        dispatch(removeAllFiltersExceptStocks());
        dispatch(resetProducts());
        dispatch(removeCurrentCategory());
        router.replace({
                pathname: '/',
                query: {},
            },
            undefined,
            {
                shallow: true,
            },
        );
    };

    return (
        <nav className="flex flex-initial items-center md:flex-nowrap flex-wrap lg:h-20 xl:h-20 2xl:h-20 bg-pdlm-blue py-4 w-full">
            <MenuButton/>
            <a className="inline-flex items-center pl-1 pr-6 cursor-pointer"
               onClick={() => restoreFilters()}>
                <LogoWhite/>
            </a>
            <div className="text-white mr-6">
                ¡Hola {user?.name}!
            </div>
            <SearchInputNav handleTextFilter={handleFilter}/>
            <a className="inline-flex p-3 rounded lg:hidden ml-auto outline-none text-white" onClick={handleClick}>
                <FontAwesomeIcon icon={faBars}/>
            </a>
            <div className={`${active ? 'flex' : 'hidden'} lg:flex flex-1`}>
                <div className="items-center flex lg:h-auto text-sm text-pdlm-gray-1 md:space-y-1 lg:space-y-0 flex-1">
                    <OfficeSelect/>
                    <CashRegister/>
                    <div className={'flex w-full h-full items-center justify-end mx-10 px-4'}>
                        <SupportIcon className={'cursor-pointer'} onClick={() => setIsOpenSupport(!isOpenMetrics)}/>
                    </div>
                    {isOpenMetrics &&
                        <Support open={isOpenMetrics} setOpen={setIsOpenSupport} displayBackButton={false}/>}
                    <div className="inline-flex items-center ml-auto mx-8">
                        <div className="relative" ref={innerBorderRef}>
                            <button
                                className="inline-flex lg:w-auto w-full pl-4 pr-3 rounded items-center bg-white py-2 relative"
                                onClick={handleShowAccountList}>
                                <div className="absolute -left-9">
                                    <UserMenuIcon/>
                                </div>
                                <p className="leading-none py-px">
                                    {user?.email}
                                </p>
                                <FontAwesomeIcon className="text-pdlm-blue ml-2.5" icon={faAngleDown}/>
                            </button>
                            {
                                showAccountMenu ?
                                    <ul
                                        className="absolute mt-1 w-40 bg-white z-40 shadow-lg max-h-56 rounded-md pt-1 text-base ring-1 ring-black
                        ring-opacity-5 overflow-auto focus:outline-none sm:text-sm custom-scrollbar">
                                        <li className="text-gray-900 text-xs cursor-pointer py-2 px-2 hover:bg-pdlm-sky-blue-hover"
                                            onClick={handleCloseSession}
                                            role="option">
                                            Cerrar Sesión
                                        </li>
                                        <hr />
                                        <li className="text-gray-400 text-xxs px-2">
                                            Versión: {versionHeader?.content}
                                        </li>
                                    </ul> : null
                            }
                        </div>
                        {/*<div className="ml-3 mr-1 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer">*/}
                        {/*    <BellIcon/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
